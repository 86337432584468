import { FormEvent, useEffect, useState } from "react"
import { useLocation }                    from "react-router"
import { Helmet }                         from "react-helmet"
import { useSelector, useDispatch }       from "react-redux"
import { RootState }                      from "../../store"
import { AuthProvider }                   from "../../store/types"
import { updateUsername }                 from "../../store/meta"


const AuthProviders = {
    twitter: {
        name: "Twitter",
        icon: "fab fa-twitter"
    },
    google: {
        name: "Google",
        icon: "fab fa-google"
    },
    github: {
        name: "GitHub",
        icon: "fab fa-github"
    },
    email: {
        name: "Email",
        icon: "fas fa-envelope"
    }
}


export default function Account()
{
    const { pathname } = useLocation();
    const dispatch     = useDispatch();

    
    const {
        user,
        identities,
        authFlashMessage,
        needsForceToConnect
    } = useSelector((state: RootState) => ({
        user               : state.meta.auth.user!,
        identities         : state.meta.auth.identities || [],
        authFlashMessage   : state.meta.auth.authFlashMessage,
        needsForceToConnect: state.meta.auth.needsForceToConnect
    }));

    const [ username, setUsername ] = useState(user.username);
    const [ flashMessage ]          = useState(authFlashMessage);
    const [ needsForce ]            = useState(needsForceToConnect);

    useEffect(() => {
        if (authFlashMessage || needsForce) {
            dispatch({ type: "set-auth", payload: { user, identities }})
        }
    }, [authFlashMessage, needsForce, dispatch, user, identities])

    function updateAccount(e: FormEvent) {
        e.preventDefault()
        dispatch(updateUsername(username))
    }

    function renderProviderRow(type: AuthProvider) {
        const identity = identities.find(i => i.provider === type)
        const { name, icon } = AuthProviders[type];
        const query = new URLSearchParams()
        query.set("redirect", pathname)
        query.set("action", "connect")
        if (needsForce === type) {
            query.set("force", "true")
        }
        if (type === "email") {
            query.set("type", "email")
        }
        const url = (type === "email" ? "/login?" : `/auth/${type}?`) + query

        return (
            <tr>
                <th><i className={ icon } /> { type === "email" && identity ? identity.external_id : name }</th>
                <td>{
                    identity ?
                        <span className="fg-brand-1"><i className="fas fa-link" /> Connected</span> :
                        <><span className="fg-grey-4"><i className="fas fa-unlink" /> Not Connected</span> - <a href={url}> Connect</a></>
                    }
                </td>
            </tr>
        )
    }

    return (
        <div className="account-card card">
            <Helmet>
                <title>My Account</title>
            </Helmet>
            <div className="card-body">
                <h1 className="text-center">My Account</h1>
                <hr/>
                <form onSubmit={ updateAccount }>
                    <div className="form-group">
                        <label htmlFor="username"><h2>Gallery Username</h2></label>
                        <div className="input-group">
                            <input type="text" name="username" id="username" required value={username} onChange={e => setUsername(e.target.value)} />
                            <button
                                type="submit"
                                disabled={ username === user.username }
                                className="btn btn-brand-2"
                            > Update </button>
                        </div>
                        <p>This is only used by app owners. If they know your username they can identify you and give you an edit access to their app.</p>
                    </div>
                </form>
                <br/>
                <br/>

                <h1 className="text-center">My Identities</h1>
                <hr/>
                <p className="fg-grey-4">
                    Connect multiple identities so that you can login into the
                    same account from any of the connected login providers. You
                    can also keep login providers unconnected to have multiple
                    accounts in the gallery.
                </p>
                <br/>
                { flashMessage && <h4 className="auth-flash-message">
                    <i className="fas fa-exclamation-triangle auth-flash-message-icon" />
                    { flashMessage }
                </h4> }
                <table className="identities-table">
                    <thead>
                        <tr>
                            <th>Provider</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        { renderProviderRow("twitter") }
                        {/* { renderProviderRow("google") } */}
                        { renderProviderRow("github") }
                        { renderProviderRow("email") }
                    </tbody>
                </table>
            </div>

            <footer className="text-center">
                <a href="/auth/logout" className="logout-link btn btn-brand-2" title="Logout">Logout</a>
            </footer>
        </div>
    );
}

