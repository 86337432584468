import { NotAuthenticatedError, NotAuthorizedError, NotFoundError } from "./errors";

export async function apiRequest(uri: string, options?: RequestInit): Promise<any>
{
    let origin = "";
    let base = `/api/`;
    uri = uri.replace(/^\//, "");

    // Doing this to avoid invalid URL errors. Don't actually request
    // anything in tests because all API requests are mocked.
    if (process.env.NODE_ENV === "test") {
        origin = require("../package.json").proxy
    }

    // For request made from the backend full url is required! Otherwise we use
    // a relative URIs that resolve to the current origin in production and to
    // the backend server in dev, thanks to the CRA proxy setting
    else if (isServer()) {
        const { HOST, PORT } = process.env;
        origin = `http://${HOST}:${PORT}`;
    }

    const url = origin + base + uri;

    // if (isDev()) {
    //     console.info(`Fetching "${url}"`);
    // }

    const res = await fetch(url, {...options, credentials: 'include' });
    
    if (!res.ok) {
        switch (res.status) {
            case 403:
                throw new NotAuthorizedError()
            case 401:
                throw new NotAuthenticatedError()
            case 404:
                throw new NotFoundError(`${uri} not found`)
        }
        const error = await humanizeError(res);
        throw error;
    }

    if (res.status === 204 || res.status === 202) { // No Content or Accepted
        return void 0;
    }

    const type = res.headers.get("Content-Type") + "";

    if (type.match(/\bjson\b/i)) {
        return res.json();
    }
    
    throw new Error("Only JSON API responses are supported");
}

export function isBrowser() {
    return typeof window === "object" || (typeof process === "object" && process.title === "browser");
}

export function isServer() {
    return !isBrowser();
}

export function isDev() {
    return process.env.NODE_ENV === "development";
}

export function getApiVersion() {
    return (isBrowser() ? localStorage.apiVersion: process.env.API_VERSION) || "v2";
}

/**
 * Used in fetch Promise chains to reject if the "ok" property is not true
 */
// async function checkResponse(resp: Response) {
//     if (!resp.ok) {
//         return await humanizeError(resp);
//     }
//     return resp;
// }

/**
 * Given a response object, generates and throws detailed HttpError.
 * @param resp The `Response` object of a failed `fetch` request
 */
async function humanizeError(resp: Response) {
    let msg = `${resp.status} ${resp.statusText}`;

    if (resp.status !== 404) {
        msg += `\nURL: ${resp.url}; `;
    }

    let body = null;

    try {
        const type = resp.headers.get("Content-Type") || "text/plain";
        if (type.match(/\bjson\b/i)) {
            body = await resp.json();
            if (body.error) {
                msg = body.error;
                if (body.error_description) {
                    msg += ": " + body.error_description;
                }
            }
            else if (Array.isArray(body.errors) && body.errors.length) {
                msg = body.errors.map((e: any) => String(e.message || e)).join("; ");
            }
            else {
                msg = JSON.stringify(body, null, 4);
            }

        }
        else if (type.match(/\bhtml\b/i)) {
            let text = await resp.text();
            if (text) {
                msg = text
                    .replace(/<.*?>/g, "")
                    .replace(/&gt;/g, ">")
                    .replace(/&lt;/g, "<")
                    .replace(/&#39;/g, "'")
                    .replace(/&nbsp;/g, " ")
                    .replace(/(\s+)at\s/g, "\n$1at ")
                    .replace(/\n+/g, "\n");
            }
        }
        else if (type.match(/^text\//i)) {
            body = await resp.text();
            if (body) {
                msg = body;
            }
        }
    } catch (_) {
        // ignore
    }

    return new Error(msg);
}

export function isEmptyObject(obj: any) {
    if (!obj || typeof obj !== "object") {
        return false;
    }
    for (let _ in obj) {
        return false;
    }
    return true;
}

/**
 * Rounds the given number @n using the specified precision.
 * @param n
 * @param precision
 * @param fixed The number of decimal units for fixed precision. For
 *   example `roundToPrecision(2.1, 1, 3)` will produce `"2.100"`, while
 *   `roundToPrecision(2.1, 3)` will produce `2.1`.
 * @returns {Number|String} Returns a number, unless a fixed precision is used
 */
export function roundToPrecision(n: number|string, precision:number = 0, fixed:number = 0) {
    n = parseFloat(n + "");

    if ( isNaN(n) || !isFinite(n) ) {
        return NaN;
    }

    if ( !precision || isNaN(precision) || !isFinite(precision) || precision < 1 ) {
        n = Math.round( n );
    }
    else {
        const q = Math.pow(10, precision);
        n = Math.round( n * q ) / q;
    }

    if (fixed) {
        n = Number(n).toFixed(fixed);
    }

    return n;
}

/**
 * Returns the byte size with units
 * @param fileSizeInBytes The size to format
 */
export function humanFileSize(fileSizeInBytes: number = 0): string {
    let i = 0;
    const base = 1024;
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    while (fileSizeInBytes >= base && i < units.length - 1) {
        fileSizeInBytes = fileSizeInBytes / base;
        i++;
    }

    return roundToPrecision(Math.max(fileSizeInBytes, 0), 1) + " " + units[i];
}
