import { useLocation } from "react-router"
import { Helmet }      from "react-helmet"

export default function AuthError({ redirect = "" })
{
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    redirect = redirect || searchParams.get("redirect") || "/";
    const msg = searchParams.get("msg") || "Authentication failed!"

    return (
        <div className="error-page card need-login card">
            <Helmet>
                <title>Authentication Error</title>
            </Helmet>
            <div className="card-body text-center">
                <h1 className="text-center fg-brand-5">Authentication Error</h1>
                <hr/>
                <p className="fg-brand-4">{ msg }</p>
                <br/>
            </div>
            <footer className="text-center">
                <a href={`/auth/twitter?redirect=${redirect}`} className="btn btn-brand-2 margin" style={{ minWidth: "14em" }}>
                    <i className="fab fa-twitter"/>&nbsp; Login with Twitter
                </a>
                <a href={`/auth/google?redirect=${redirect}`} className="btn btn-brand-2 margin" style={{ minWidth: "14em" }}>
                    <b><i className="fab fa-google"/>&nbsp; Login with Google</b>
                </a>
                <a href={`/auth/github?redirect=${redirect}`} className="btn btn-brand-2 margin" style={{ minWidth: "14em" }}>
                    <b><i className="fab fa-github"/>&nbsp; Login with GitHub</b>
                </a>
            </footer>
        </div>
    );
}
