export const fhirSettings = {
    'DSTU-2': {
        label: "DSTU 2",
        launcher: "https://launch.smarthealthit.org/launcher",
        versionNumber: 2,
        tags: [],
        patientPicker: {
            origin: 'https://patient-browser.smarthealthit.org',
            path  : '/index.html?config=r2',
            width : 1000,
            height: 700,
            selectedTags: ['smart-8-2017', 'synthea-8-2017']
        }
    },
    'STU-3': {
        label: "STU 3",
        launcher: "https://launch.smarthealthit.org/launcher",
        versionNumber: 3,
        patientPicker: {
            origin: 'https://patient-browser.smarthealthit.org',
            path  : '/index.html?config=r3',
            width : 1000,
            height: 700,
            selectedTags: ['pro-7-2017', 'smart-7-2017', 'synthea-7-2017']
        }
    },
    R4: {
        label: "R4",
        launcher: "https://launch.smarthealthit.org/launcher",
        versionNumber: 4,
        patientPicker: {
            origin: 'https://patient-browser.smarthealthit.org',
            path  : '/index.html?config=r4',
            width : 1000,
            height: 700,
            selectedTags: ['synthea-5-2019']
        }
    }
};

const config = {
    fhir: fhirSettings,
    uploads: {
        maxUploadBytes: 1024 * 1024 * 10,
        acceptedMimeTypes: [
            "image/png",
            "image/jpeg"
        ]
    },
    advancedSearch: {
        enabled: false
    },
    GATrackingId: "UA-61710281-2"
};

export default config;
