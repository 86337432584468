import React    from "react"
import { Link } from "react-router-dom"

interface PaginatorProps {
    page: number
    pageCount: number
}

export default class Paginator extends React.Component<PaginatorProps>
{
    render()
    {
        const { page, pageCount } = this.props;

        if (pageCount <= 1) {
            return <div className="pagination-wrapper" />;
        }

        const pages = [];
        for (let i = Math.max(1, page - 2); i < Math.min(pageCount + 1, page + 3); i++) {
            pages.push(
                <Link
                    key={ i }
                    to={ loc => ({
                        search: loc.search,
                        pathname: loc.pathname.replace(/(\/*page\/\d+)?$/, `/page/${i}`)
                    })}
                    className={`page-link${page === i ? "  active" : ""}`}>{i}</Link>
            );
        }
        let prevLink = null;
        let nextLink = null;
        if (page > 1) {
            prevLink = <Link to={loc => ({
                search: loc.search,
                pathname: loc.pathname.replace(/(\/*page\/\d+)?$/, `/page/${page - 1}`)
            })} className="previous-page btn btn-brand-2">Previous</Link>
        }
        if (page < pageCount) {
            nextLink = <Link to={loc => ({
                search: loc.search,
                pathname: loc.pathname.replace(/(\/*page\/\d+)?$/, `/page/${page + 1}`)
            })} className="next-page btn btn-brand-2">Next</Link>
        }
        return (
            <div className="pagination-wrapper">
                { prevLink }
                { pages }
                { nextLink }
            </div>
        );
    }
}

