export class ClientError extends Error {
    constructor(message: string = "Client error") {
        super(message)
    }
}

export class ServerError extends Error {
    constructor(message: string = "Server error") {
        super(message)
    }
}

export class NotAuthenticatedError extends ClientError {
    constructor(message: string = "Not authenticated") {
        super(message)
    }
}

export class NotAuthorizedError extends ClientError {
    constructor(message: string = "Not authorized") {
        super(message)
    }
}

export class NotFoundError extends ClientError {
    constructor(message: string = "Not found") {
        super(message)
    }
}

export class ValidationError extends ClientError {
    constructor(message: string = "Validation failed") {
        super(message)
    }
}