import React, { PropsWithChildren, ReactElement } from "react"
import ReactDOM       from "react-dom"
import Dialog         from "../Dialog"
import Loader         from "../Loader"
import { apiRequest } from "../../lib"

interface ContactDialogFormData {
    name        : string
    organization: string
    role        : string
    ehr         : string
    email       : string
    phone       : string
    text        : string
    appId       : string
}

interface ContactDialogProps {
    title    ?: string
    formData ?: ContactDialogFormData
    appId     : string
    emailFrom?: string
}

interface ContactDialogState {
    sending: boolean
    mailError: string
    formData: ContactDialogFormData
}


export function showContactDialog(props: PropsWithChildren<ContactDialogProps>)
{
    ReactDOM.render(
        <ContactDialog {...props} />,
        document.getElementById("dialog-wrapper")
    );
}


export default class ContactDialog extends React.Component<ContactDialogProps, ContactDialogState>
{
    static defaultProps = {
        formData: {}
    };

    constructor(props: ContactDialogProps)
    {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            formData: Object.assign({
                name        : "",
                organization: "",
                role        : "",
                ehr         : "",
                email       : this.props.emailFrom || "",
                phone       : "",
                text        : "",
                appId       : this.props.appId
            }, this.props.formData),
            sending: false,
            mailError: "",
            // closed: false
        };
    }

    close()
    {
        const node = ReactDOM.findDOMNode(this);
        if (node && node.parentElement) {
            ReactDOM.unmountComponentAtNode(node.parentElement);
        }
    }

    onSubmit(e: React.FormEvent)
    {
        e.preventDefault();
        this.setState({ sending: true }, () => {
            apiRequest(`/apps/${this.state.formData.appId}/contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(this.state.formData)
            })
            .then(
                () => this.setState({ sending: false }, () => this.close()),
                mailError => this.setState({ sending: false, mailError }, () => {
                    setTimeout(() => this.close(), 5000);
                })
            );
        });
    }

    renderFormControl(type: string, props: any, children?: ReactElement)
    {
        const { name } = props;
        props.id = `form-element-${name}`;
        // @ts-ignore
        props.value = this.state.formData[name];
        props.onChange = (e: React.ChangeEvent<HTMLFormElement>) => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: e.target.value
                }
            });
        };

        if (type === "textarea") {
            return <textarea {...props}/>;
        }

        else if (type === "select") {
            return <select {...props}>{children}</select>;
        }

        props.type = type;
        return <input {...props}/>;
    }

    /**
     * Renders a form element label
     * @param options The options to control the result.
     * @param [options.fullWidth] If true the label will have full-width.
     * @param [options.required] If true add red "*" after the label text.
     * @param {String} options.for (required) The name (NOT THE ID) of the
     *                  associated form element.
     * @param {String} options.text The actual text to render.
     * @returns {JSX.Element} The label
     */
    renderLabel(options: { fullWidth?: boolean, text: string, required?: boolean, for: string })
    {
        const className = options.fullWidth ?
            "flex-col-sm-10" :
            "flex-col-sm-4 flex-col-md-4 flex-col-lg-2 flex-col-sm-right";
        return (
            <label className={className} htmlFor={`form-element-${options.for}`}>
                {options.text}{options.required && <b className="text-danger"> *</b>}
            </label>
        );
    }

    /**
     * Renders the form elements within the dialog body
     */
    renderForm()
    {
        return (
            <form action="/" onSubmit={ this.onSubmit } className="contact-form" id="contact-form">
                <div className="flex-row">
                    {this.renderLabel({ text: "Your Name", for: "name", required: true })}
                    <div className="flex-col-sm-6 flex-col-md-6 flex-col-lg-3">
                        { this.renderFormControl("text", { name: "name", required : true }) }
                    </div>
                    { this.renderLabel({ text: "Organization", for: "organization" })}
                    <div className="flex-col-sm-6 flex-col-md-6 flex-col-lg-3">
                        { this.renderFormControl("text", { name: "organization" }) }
                    </div>
                </div>
                <div className="flex-row">
                    {this.renderLabel({ text: "Your Role", for: "role", required : true })}
                    <div className="flex-col-sm-6 flex-col-md-6 flex-col-lg-3">
                        { this.renderFormControl("select", { name: "role", required : true }, <>
                            <option value="">Please Select</option>,
                            <option value="Clinical">Clinical</option>,
                            <option value="Administrative">Administrative</option>,
                            <option value="IT">IT</option>,
                            <option value="Patient">Patient</option>,
                            <option value="Other">Other</option>
                        </>) }
                    </div>
                    { this.renderLabel({ text: "EHR Vendor", for: "ehr" })}
                    <div className="flex-col-sm-6 flex-col-md-6 flex-col-lg-3">
                        { this.renderFormControl("text", {
                            name: "ehr",
                            placeholder: "(if applicable)"
                        }) }
                    </div>
                </div>
                <div className="flex-row">
                    { this.renderLabel({ text: "Your Email", for: "email", required : true })}
                    <div className="flex-col-sm-6 flex-col-md-6 flex-col-lg-3">
                        { this.renderFormControl("email", {
                            name: "email",
                            pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                            required : true
                        }) }
                    </div>
                    { this.renderLabel({ text: "Phone", for: "phone" })}
                    <div className="flex-col-sm-6 flex-col-md-6 flex-col-lg-3">
                        { this.renderFormControl("phone", { name: "phone" }) }
                    </div>
                </div>
                <br/>
                <div className="flex-row">
                    { this.renderLabel({
                        text     : "Question / Comments",
                        required : true,
                        for      : "text",
                        fullWidth: true
                    })}
                    <div className="flex-col-xs-10">
                        { this.renderFormControl("textarea", {
                            rows: 10,
                            required : true,
                            name: "text"
                        }) }
                    </div>
                </div>
            </form>
        );
    }

    render()
    {
        const { sending, mailError } = this.state;
        return (
            <Dialog
                title={ this.props.title }
                titleIcon={ <i className="fa fa-envelope"/> }
                onClose={ () => this.close() }
                modal
                className="contact-dialog"
                footer={
                    <div className="flex-row" style={{ justifyContent: "flex-end" }}>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            style={{ width: "8em", margin: "0 1ex 0 0" }}
                            onClick={ () => this.close() }
                        >Cancel</button>
                        <button
                            type="submit"
                            className="btn btn-brand-1"
                            style={{ width: "8em" }}
                            form="contact-form"
                        >Send</button>
                    </div>
                }
            >
                { mailError ?
                    <div className="sending-loader has-error">{ String(mailError) }</div> :
                    sending ?
                        <div className="sending-loader">
                            <Loader>Sending...</Loader>
                        </div> :
                        null
                }
                { this.renderForm() }
            </Dialog>
        );
    }
}
