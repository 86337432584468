const LINK_REGEXP = /((https?:\/\/|www\.)[^\s\])]+)/g;

function text2html(text = "") {
    return text.replace(LINK_REGEXP, function(url) {
        return `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>`;
    }).split('\n').map(p => `<p>${p}</p>`).join("");
}

export default function TextWithLinks({ children, ...props }: { children: string, [key: string]: any })
{
    return (
        <div {...props} dangerouslySetInnerHTML={{ __html: text2html(children) }}/>
    );
}
