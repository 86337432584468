import React    from "react"
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"

export default function Error403()
{
    return (
      <div className="error-page card">
          <Helmet>
              <title>Permission denied</title>
          </Helmet>
          <div className="error-message card-body text-center">
            <h1>403</h1>
            <h4>Permission Denied</h4>
            <p><small className="fg-grey-4">You are not authorized to access this location, or to perform this action</small></p>
          </div>
          <footer className="text-center">
            <Link className="btn btn-brand-2" to="/">Go Home</Link>
            <a className="btn btn-brand-2" target="_top" href="mailto:gallery@smarthealthit.org?subject=Contact">
              Contact Us
            </a>
          </footer>
      </div>
    );
}
