import React from "react"
import Tag from "../Tag/Tag"

interface MultiSelectOption<ValueType> {
    value: ValueType
    label: string
    id  ?: string
}

interface MultiSelectProps<ValueType = any> {
    onChange      : (values: ValueType[]) => any
    maxSelections?: number
    required     ?: boolean
    error        ?: string
    values        : MultiSelectOption<ValueType>[]
    options       : MultiSelectOption<ValueType>[]
    [key: string] : any
}

interface MultiSelectState {
    hasFocus: boolean
    highlightedIndex: number
}


export default class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState>
{   
    static defaultProps = {
        onChange: () => 1
    };

    static instanceCount: number = 0;

    main: HTMLDivElement | null = null;

    constructor(props: MultiSelectProps)
    {
        super(props);

        // bind methods with variable context
        this.onFocus   = this.onFocus.bind(this);
        this.onBlur    = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.state = {
            hasFocus: false,
            highlightedIndex: -1
        };
    }

    // Component life-cycle methods --------------------------------------------

    /**
     * Make sure the highlighted index is not out of bounds. If it is, correct
     * it. Otherwise scroll the highlighted option into view if needed.
     */
    componentDidUpdate()
    {
        const { options, values } = this.props;
        const { highlightedIndex, hasFocus } = this.state;
        const optionsLength = options.length - values.length;
        const minIndex = optionsLength ? 0 : -1;
        const maxIndex = optionsLength - 1;

        if (highlightedIndex < minIndex || highlightedIndex > maxIndex) {
            this.setState({
                highlightedIndex: Math.min(Math.max(highlightedIndex, minIndex), maxIndex)
            });
        }
        else if (hasFocus && highlightedIndex > -1) {
            let option = (this.main as HTMLDivElement).querySelector(".select-option.highlighted");
            if (option) {
                option.scrollIntoView({ block: "nearest" });
            }
        }
    }

    // Event handlers ----------------------------------------------------------

    /**
     * Sets this.state.hasFocus to true which will open the menu
     * @returns {void}
     */
    onFocus()
    {
        this.setState({ hasFocus: true });
    }

    /**
     * Sets this.state.hasFocus to false which will close the menu
     * @returns {void}
     */
    onBlur()
    {
        this.setState({ hasFocus: false });
    }

    /**
     * Close the menu and add the clicked option.
     */
    onOptionClick<T>(value: T)
    {
        setTimeout(() => (this.main as HTMLDivElement).blur());
        this.selectValue(value);
    }

    onKeyDown(event: React.KeyboardEvent)
    {
        const { highlightedIndex, hasFocus } = this.state;
        switch (event.key) {
            case "Enter":
                event.preventDefault();
                if (highlightedIndex >= 0) {
                    const list = this.getUnselectedOptions();
                    const selectedOption = list[highlightedIndex];
                    if (selectedOption) {
                        this.selectValue(selectedOption.value);
                    }
                }
                break;
            case "Escape":
                event.preventDefault();
                if (hasFocus) {
                    (this.main as HTMLDivElement).blur();
                }
                break;
            case "ArrowDown":
                {
                    event.preventDefault();
                    if (!hasFocus) {
                        return this.setState({ hasFocus: true });
                    }
                    const len = this.getUnselectedOptions().length;
                    if (highlightedIndex < len - 1) {
                        this.setState({
                            highlightedIndex: highlightedIndex + 1
                        });
                    }
                }
                break;
            case "ArrowUp":
                event.preventDefault();
                if (highlightedIndex > 0) {
                    this.setState({
                        highlightedIndex: highlightedIndex - 1
                    });
                }
                break;
            default:
                break;
        }

        return true;
    }

    // Private helpers ---------------------------------------------------------

    getUnselectedOptions()
    {
        const { values, options } = this.props;
        return options.filter(o => !values.find(x => x.id === o.id));
    }

    selectValue<T>(value: T)
    {
        const { values, maxSelections = Infinity } = this.props;
        if (values.length < maxSelections) {
            this.props.onChange([
                ...this.props.values.map(v => v.value),
                value
            ]);
        }
    }

    // Rendering methods -------------------------------------------------------

    renderOptions()
    {
        const { hasFocus, highlightedIndex } = this.state;

        if (!hasFocus) {
            return null;
        }

        const { values, maxSelections = Infinity } = this.props;

        const unselectedOptions = this.getUnselectedOptions();

        if (!unselectedOptions.length) {
            return null;
        }

        const full = values.length >= maxSelections;
        
        const options = unselectedOptions.map((option, i) => {
            let className = "select-option";

            if (full) {
                className += " disabled";
            }
            
            if (highlightedIndex === i) {
                className += " highlighted";
            }
    
            return (
                <div
                    className={className}
                    key={i}
                    onMouseDown={full ? undefined : this.onOptionClick.bind(this, option.value)}
                >
                    { option.label }
                </div>
            );
        });

        return (
            <div className="select-options">
                { full && <div className="select-options-message">Max {maxSelections} selections</div>}
                {options}
            </div>
        );
    }

    renderValue()
    {
        const { values, onChange } = this.props;

        return values.map((opt, i) => {
            return <Tag
                key={i}
                label={opt.label}
                onRemove={() => onChange(values.filter((_, e) => e !== i).map(x => x.value))}
            />;
        });
    }

    render()
    {
        const { error, required, values, onChange, maxSelections, options, id, ...rest } = this.props;
        return (
            <div
                className={"multi-select form-control" + (required && !values.length ? " invalid": "")}
                tabIndex={0}
                ref={ node => this.main = node }
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                title={ required && !values.length ? error || "Please Select" : undefined }
                { ...rest }
            >
                <div className="select-value">{ this.renderValue() }</div>
                { required && !values.length && <div className="error">{error || "Please Select"}</div> }
                <input
                    type="text"
                    className="validation-helper"
                    id={id || `MultiSelectHelper-${++MultiSelect.instanceCount}`}
                    value={ values.length ? 1 : "" }
                    required={required}
                    tabIndex={-1}
                    onFocus={() => this.main && this.main.focus() }
                    onChange={() => {}}
                />
                { this.renderOptions() }
            </div>
        );
    }
}


