import { Dispatch }    from "redux"
import { apiRequest }  from "../lib"
import { ThunkAction } from "redux-thunk"
import { RootState }   from "./index"
import {
    ApplicationTypeState,
    AuthState,
    CategoriesState,
    DesignedForState,
    EHRState,
    FHIRState,
    OSState,
    PricingState,
    SpecialtiesState
} from "./types";


export interface MetaState {
    applicationType: ApplicationTypeState
    auth: AuthState
    categories: CategoriesState
    designedFor: DesignedForState
    ehr: EHRState
    fhir: FHIRState
    operatingSystems: OSState
    pricing: PricingState
    specialties: SpecialtiesState
    loading: boolean
}

interface LoadMetaAction {
    type: "load"
    payload: MetaState
}

interface SetLoadingMetaAction {
    type: "set-loading"
    payload: boolean
}

interface SetAuthAction {
    type: "set-auth"
    payload: AuthState
}

interface SetUsernameAction {
    type: "set-username"
    payload: string
}

type AnyAction = LoadMetaAction | SetLoadingMetaAction | SetAuthAction | SetUsernameAction;

const initialState: MetaState = {
    applicationType: [],
    auth: { user: null, identities: [] },
    categories: [],
    designedFor: [],
    ehr: [],
    fhir: [],
    operatingSystems: [],
    pricing: [],
    specialties: [],
    loading: false
};

export function updateUsername(username: string): ThunkAction<any, RootState, unknown, any> {
    return function (dispatch: Dispatch) {
        apiRequest("/user", {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ username }),
            credentials: "include"
        }).then(
            user => {
                dispatch({ type: "set-username", payload: user.username });
            },
            console.error
        )
    }
}


export default function categoriesReducer(state = initialState, action: AnyAction ): MetaState
{
    if (action.type === "set-loading") {
        return { ...state, loading: !!action.payload };
    }
    if (action.type === "set-auth") {
        return { ...state, auth: action.payload };
    }
    if (action.type === "load") {
        return action.payload;
    }
    if (action.type === "set-username") {
        return {
            ...state,
            auth: {
                ...state.auth,
                user: {
                    ...state.auth.user!,
                    username: action.payload
                }
            }
        };
    }
    return state;
}
