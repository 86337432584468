import ReactDOM                 from "react-dom"
import { Provider }             from "react-redux"
import { BrowserRouter }        from "react-router-dom"
import store                    from "./store"
import App                      from "./containers/App"

import "./styles/main.scss"


// @ts-ignore
let preloadedState = window.__preloadedState || {};

// @ts-ignore
window.__preloadedState = null;

const root = document.getElementById('root');

if (root) {
    ReactDOM[root.childNodes.length ? "hydrate" : "render"](
        <BrowserRouter>
            <Provider store={ store(preloadedState) }>
                <App />
            </Provider>
        </BrowserRouter>,
        root
    );
}
