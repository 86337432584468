import React from "react"

type AppUserRole = "author" | "editor"

interface AppUser {
    role  : AppUserRole
    username: string
}

interface UserManagerProps {
    users: AppUser[]
    onChange: (users: AppUser[]) => any
}

interface UserManagerState {
    value: string
}

export default class UserManager extends React.Component<UserManagerProps, UserManagerState>
{
    constructor(props: UserManagerProps)
    {
        super(props);
        this.state = {
            value: ""
        };
    }

    submit() {
        const username = this.state.value.trim();
        if (username) {
            this.setState({ value: "" }, () => {
                this.props.onChange([
                    ...this.props.users,
                    { username, role: "editor" }
                ]);
            });
        }
    }

    render()
    {
        const { users, onChange } = this.props;

        const newUsername = this.state.value;

        return (
            <div className="user-manager">
                <label htmlFor="editors">
                    Enter the App Gallery usernames of other users who should be able to edit this listing.
                    If you know the user(s) you want to grant access, please ask them to login in the gallery,
                    go to their account page and tell you what their username is.
                </label>
                <div style={{ margin: "0.5rem -2px" }}>
                    {users.map((u, i) => (
                        <div key={i} className="tag">
                            <i className="fas fa-user"/> {u.username} {
                                u.role !== "author" && <i
                                    className="fas fa-times-circle close-btn"
                                    title="Remove"
                                    onClick={e => onChange(users.filter(x => u.username !== x.username))}
                                />
                            }
                        </div>
                    ))}
                </div>
                <div className="input-group">
                    <input
                        id="editors"
                        type="text"
                        placeholder="SMART App Gallery Username"
                        value={ newUsername }
                        onChange={ e => this.setState({ value: e.target.value }) }
                        onKeyDown={e => {
                            if (newUsername && e.key === "Enter") {
                                e.preventDefault();
                                this.submit();
                            }
                        }}
                    />
                    <button
                        type="button"
                        disabled={!newUsername.trim()}
                        className="btn btn-brand-2"
                        onClick={() => this.submit()}
                    > Add </button>
                </div>
            </div>
        )
    }
}

