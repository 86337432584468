import React, { ReactElement } from "react"

interface DialogProps {
    /**
     * Text to render in the dialog header. If empty, the dialog
     * will have no header
     */
    title?: string
    
    /**
     * An icon to put in front of the dialog header text. Ignored
     * if `title` is not set
     */
    titleIcon?: ReactElement

    /**
     * If true, clicking outside of the dialog will not close it 
     */
    modal?: boolean

    /**
     * The contents of the dialog body
     */
    children?: any,

    /**
     * A callback function to be called when the user wants to close
     * the dialog (through the "X" icon, Esc key or otherwise).
     * NOTE that the dialog does not know how to remove itself so
     * this function must be provided
     */
    onClose: () => any

    /**
     * If provided, this will be rendered as a dialog footer (inside
     * a `<div className="dialog-footer">`) 
     */
    footer?: ReactElement

    /**
     * ClassName(s) to append to the dialog and customize it
     */
    className?: string
}

export default class Dialog extends React.Component<DialogProps>
{
    constructor(props: DialogProps)
    {
        super(props);
        this.onGlobalKeyDown = this.onGlobalKeyDown.bind(this);
    }

    componentDidMount()
    {
        document.body.scrollIntoView();
        window.addEventListener("keydown", this.onGlobalKeyDown, false);
    }

    componentWillUnmount()
    {
        window.removeEventListener("keydown", this.onGlobalKeyDown);
    }

    onGlobalKeyDown(event: KeyboardEvent): void
    {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    }

    render()
    {
        const { modal, title, titleIcon, children, footer, onClose, className } = this.props;
        return (
            <>
                <div className="dialog-overlay" onClick={ modal ? undefined : onClose }/>
                <div className={"dialog " + (className || "")}>
                    { title && (
                        <div className="dialog-header">
                            <div title={ title }>
                                {
                                    titleIcon ?
                                    <span>{titleIcon} &nbsp;</span> :
                                    null
                                }
                                <span>{ title }</span>
                                <i
                                    className="fa fa-times-circle dialog-close-button"
                                    title="Close"
                                    onClick={ onClose }
                                />
                            </div>
                        </div>)
                    }
                    <div className="dialog-body">
                        { children }
                    </div>
                    { footer && (
                        <div className="dialog-footer">{ footer }</div>
                    ) }
                </div>
            </>
        );
    }
}
