import React          from "react"
import { Redirect, RouteComponentProps, withRouter } from "react-router"
import { Link }       from "react-router-dom"
import { apiRequest } from "../../lib"
import { App }        from "../../store/types"
import Error403       from "../Error403"
import ErrorMessage   from "../ErrorMessage"

interface ConfirmAppDeleteParams {
    slug: string
}

interface ConfirmAppDeleteProps extends RouteComponentProps<ConfirmAppDeleteParams> {}

interface ConfirmAppDeleteState {
    app    : App | null
    loading: boolean
    error  : Error | null
    deleted: boolean
}

class ConfirmAppDelete extends React.Component<ConfirmAppDeleteProps, ConfirmAppDeleteState>
{
    constructor(props: ConfirmAppDeleteProps)
    {
        super(props);
        this.state = {
            app    : null,
            error  : null,
            loading: true,
            deleted: false
        };
    }

    componentDidMount()
    {
        this.load();
    }

    load()
    {
        this.setState({ loading: true, error: null }, () => {
            apiRequest(`/app/${this.props.match.params.slug}`)
            .then(app => this.setState({ loading: false, app }))
            .catch(error => this.setState({ loading: false, error }));
        });
    }

    destroy()
    {
        this.setState({ loading: true, error: null }, () => {
            apiRequest(
                `/app/${this.state.app?.id}`,
                { method: "DELETE", credentials: "include" }
            ).then(
                () => this.setState({ loading: false, deleted: true }),
                error => this.setState({ error, loading: false })
            );
        });
    }

    render()
    {
        const { app, loading, error, deleted } = this.state;

        if(loading) {
            return null
        }

        if (!app?.user_actions?.can_delete) {
            return <Error403/>
        }

        if (error) {
            return <ErrorMessage error={ error }/>
        }

        if (deleted) {
            return <Redirect to="/user/apps" />
        }

        return (
            <div className="card">
                <header className="fg-brand-5">
                    <i className="fas fa-question-circle"/> Please Confirm
                </header>
                <div className="card-body text-center">
                    <br/>
                    <p>Are you sure you want to delete the "<b>{app?.name}</b>" app?</p>
                    <br/>
                </div>
                <footer className="text-center">
                    <Link replace style={{ width: "8em" }} className="btn btn-secondary" to={`/app/${app?.slug}/edit`}>Cancel</Link>
                    <button style={{ width: "8em" }} className="btn btn-danger" onClick={() => this.destroy()}>Delete</button>
                </footer>
            </div>
        );
    }
}

export default withRouter(ConfirmAppDelete);
