import{ Redirect, RouteComponentProps, withRouter } from "react-router"
import React              from "react"
import { connect }        from "react-redux"
import { Helmet }         from "react-helmet"
import { RootState }      from "../store"
import { User, UserRole } from "../store/types"
import Login              from "../Login"


interface RequireLoginProp extends RouteComponentProps {
    
    /**
     * The current user (if any)
     */
    user?: User | null

    /**
     * The required role
     */
    role?: UserRole

    children?: React.ReactNode

    loading: boolean

    redirect?: string
}

class RequireLogin extends React.Component<RequireLoginProp>
{
    render()
    {
        const { user, role, children, location, loading } = this.props;

        if (loading) {
            return null
        }

        const searchParams = new URLSearchParams(location.search);

        let redirect = (
            this.props.redirect ||
            searchParams.get("redirect") ||
            (location.pathname === "/user/login" ? "/" : location.pathname) ||
            "/"
        );

        if (user && (!role || user.role === role)) {
            if (children) {
                return children;
            }
            return <Redirect to={redirect} />
        }

        return (
            <>
                <Helmet>
                    <title>Login Required</title>
                </Helmet>
                <h1>Login is Required</h1>
                <Login redirect={ redirect } />
            </>
        )
    }
}

export default connect(
    (state: RootState) => ({
        user: state.meta.auth.user,
        loading: state.meta.loading
    })
)(withRouter(RequireLogin));
