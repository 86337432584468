import React from "react"
import { AppDemoType } from "../store/types";

const demoTypeToFhirVersion = {
    'DSTU-2': 2,
    'STU-3' : 3,
    'R4'    : 4,
    'R5'    : 5
};

let launcherBaseUrl = "https://launch.smarthealthit.org/launcher";

interface iTryAppButtonProps {
    demo_launch_url: string;

    /**
     * Comma-separated list of patient IDs
     */
    demo_patient_ids?: string;

    demo_type: AppDemoType;
    
    disabled?: boolean;
    children?: React.ElementType | string | null;
}

export default function TryAppButton({ demo_launch_url, demo_patient_ids, demo_type, disabled = false, children = null }: iTryAppButtonProps)
{
    if (demo_type === "none" || String(demo_type || "none").indexOf("none") === 0) {
        return null;
    }

    let url = launcherBaseUrl + "?";
    const query = [];

    // launch_uri
    query.push(["launch_uri", demo_launch_url]);

    // Pre-selected patient(s) if any
    if (demo_patient_ids && demo_patient_ids.length > 0) {
        query.push(["patient", demo_patient_ids.replace(/\s*/g, '')]);
    }

    // The numeric fhir version
    query.push(["fhir_ver", demoTypeToFhirVersion[demo_type]]);

    // Build the url
    url += query.map(tuple => tuple.map(encodeURIComponent).join("=")).join("&");

    return (
        <a
            target="_blank"
            rel="noreferrer"
            className={"btn btn-brand-2" + (disabled ? " disabled" : "")}
            href={url}
        >
            { children || "Try App" }
        </a>
    )
}

