import React from "react"
import ListEntry  from "../ListEntry/index"
import Paginator  from "../Paginator/index"
import Select     from "../Select/index"
import AdvancedSearchForm from "../AdvancedSearchForm"
import Loader     from "../Loader"
import { App, AppsSortType } from "../../store/types"
import { MetaState } from "../../store/meta"


const supportedSortOptions = [
    { value: "name-asc", label: "Name (A-Z)" },
    { value: "name-desc", label: "Name (Z-A)" },
    { value: "created-desc", label: "Date Created" },
    { value: "updated-desc", label: "Date Updated" },
    { value: "rank", label: "Relevance", searchOnly: true }
];

export interface AppListProps {
    loading: boolean
    apps: App[]
    title: string
    page?: number
    pageCount?: number
    totalCount?: number
    onSortChange?: (sort: AppsSortType) => any
    showAdvancedSearch?: boolean
    sort?: AppsSortType
    groupByStatus?: boolean
    meta: MetaState
}

export default class AppList extends React.PureComponent<AppListProps>
{
    renderItems()
    {
        const { groupByStatus, apps, loading, meta } = this.props;

        if (!apps.length) {
            return loading ? null : "No apps found!"
        }

        if (groupByStatus) {
            const groups: {[key: string]: App[]} = {};

            apps.forEach(app => {
                const groupName = app.status.toUpperCase();

                if (!groups[groupName]) {
                    groups[groupName] = [];
                }

                groups[groupName].push(app);
            });

            return (
                <>
                    {
                        Object.keys(groups).map(groupName => (
                            <div key={groupName}>
                                <div className="group-header">
                                    <h4>{groupName}</h4>
                                </div>
                                { groups[groupName].map(app => (
                                    <ListEntry
                                        app={ app }
                                        designedFor={ meta.designedFor }
                                        specialties={ meta.specialties }
                                        os={ meta.operatingSystems }
                                        user={ meta.auth.user }
                                        key={ app.id }
                                    />
                                )) }
                            </div>
                        ))
                    }
                </>
            )
        }

        return apps.map(app => (
            <ListEntry
                app={ app }
                designedFor={ meta.designedFor }
                specialties={ meta.specialties }
                os={ meta.operatingSystems }
                user={ meta.auth.user }
                key={ app.id }
            />
        ))
    }

    render()
    {
        const {
            loading,
            title,
            page,
            pageCount,
            totalCount,
            onSortChange,
            sort
        } = this.props;

        return (
            <div className="app-list">
                {
                    this.props.showAdvancedSearch ?
                    <AdvancedSearchForm /> :
                    <header>
                        <h2>{ title }{ !loading && (totalCount || totalCount === 0) ? <b> <b className="badge bg-grey-4">{ totalCount }</b></b> : null } { loading && <Loader/> }</h2>
                        <div className="spacer"/>
                        { onSortChange && sort && <div className="sort-selector">
                            <Select
                                valuePrefix="Sort: "
                                value={sort}
                                // options={ searching ? supportedSortOptions : supportedSortOptions.filter(o => !o.searchOnly) }
                                options={ supportedSortOptions.filter(o => !o.searchOnly) }
                                onChange={ (sort: AppsSortType) => onSortChange(sort) }
                            />
                        </div> }
                    </header>
                }
                <div className="apps" style={ loading ? { opacity: 0.75, filter: "grayscale(1)" } : undefined }>
                    { this.renderItems() }
                </div>
                { page && pageCount ? <Paginator page={ page } pageCount= { pageCount }/> : null }
            </div>
        )
    }
}
