import React            from "react"
import{ RouteChildrenProps, withRouter } from "react-router-dom"
import { connect }      from "react-redux"
import { load, unload } from "../store/selectedApp"
import AppView          from "../components/AppView/index"
import { RootState }    from "../store"
import Loader           from "../components/Loader"
import { App }          from "../store/types"
import { MetaState }    from "../store/meta"
import ErrorMessage from "../components/ErrorMessage"

interface ViewAppParams {
    slug: string
}

interface ViewAppProps extends RouteChildrenProps<ViewAppParams> {
    match: any
    data: App|null
    load: (slug: string) => void
    unload: () => any
    loading: boolean
    error?: Error|null
    meta: MetaState
}

class ViewApp extends React.Component<ViewAppProps>
{
    componentDidUpdate()
    {
        this.load();
    }

    componentDidMount()
    {
        this.load();
    }

    // componentWillUnmount()
    // {
    //     this.props.unload()
    // }

    load()
    {
        const { data, match, load, loading, error } = this.props;
        const slug = match.params.slug;
        if (!loading && !error && (!data || data.slug !== slug)) {
            load(slug);
        }
    }

    render()
    {
        const { loading, error, data, meta } = this.props;

        if (loading) {
            return <div className="card app-view text-center"><Loader>Loading...</Loader></div>
        }

        if (error) {
            return <ErrorMessage error={error}/>
        }

        if (!data) {
            return null;
        }

        return <AppView app={data} meta={meta} />
    }
}

export default connect(
    (state: RootState) => ({
        data   : state.selectedApp.data,
        loading: state.selectedApp.loading,
        error  : state.selectedApp.error,
        meta   : state.meta
    }),
    {
        load,
        unload
    }
)(withRouter(ViewApp));
