import React            from "react"
import { connect }      from "react-redux"
import { Helmet }       from "react-helmet"
import { withRouter, Redirect, RouteComponentProps, Link } from "react-router-dom"
import { load, unload } from "../store/apps"
import AppList          from "../components/AppList/index"
import { RootState }    from "../store"
import { App, AppsFilterType } from "../store/types"
import Loader from "../components/Loader"
import { MetaState } from "../store/meta"
import ErrorMessage from "../components/ErrorMessage"
import { unload as unloadSelectedApp } from "../store/selectedApp"

interface AppListContainerParams {
    filterType?: AppsFilterType
    filterValue?: string
    page?: string
}

interface AppListContainerProps extends RouteComponentProps<AppListContainerParams> {
    apps   : App[]
    loading: boolean
    error  : Error | null
    uri    : string
    meta   : MetaState
    load   : (options: any) => any
    unload : () => any
    unloadSelectedApp: () => any
}

class AppListContainer extends React.Component<AppListContainerProps>
{
    componentDidMount()
    {
        this.load();
        this.props.unloadSelectedApp()
    }

    componentWillUnmount()
    {
        this.props.unload();
    }

    load()
    {
        this.props.load({
            filterType: "my-apps",
            sort: "updated-desc"
        });
    }

    render()
    {
        const {
            apps,
            loading,
            match,
            error,
            meta,
            location
        } = this.props;

        if (error) {
            return <ErrorMessage error={error}/>
        }

        if (match.params.filterType === "review") {
            if (!meta.auth.user) {
                return <Redirect to={`/user/login?redirect=${location.pathname}`}/>
            }
        }

        // @ts-ignore
        const saved = location.state && location.state.saved;

        return (
            <>
                <Helmet>
                    <title>Your Listings</title>
                </Helmet>
                {
                    loading ?
                        <Loader>Loading...</Loader> :
                        <>
                            { saved && <h5 className="alert alert-success">Changes saved successfully!</h5> }
                            { apps.length > 0 ?
                                <AppList
                                    loading={ loading }
                                    apps={ apps }
                                    meta={meta}
                                    title="Your Listings"
                                    showAdvancedSearch={ this.props.match.params.filterType === "advanced-search" }
                                    groupByStatus
                                /> :
                                <div className="text-center">
                                    <br/>
                                    <h2>Your Listings</h2>
                                    <hr/>
                                    <div className="fg-grey-4">You have no listings yet</div>
                                    <br/>
                                    <Link to="/app/new" className="btn btn-brand-2">Add New Listing</Link>
                                </div>
                            }
                        </>
                }
            </>
        );
    }
}

export default connect(
    (state: RootState) => ({
        apps   : state.apps.items,
        loading: state.apps.loading,
        error  : state.apps.error,
        uri    : state.apps.uri,
        meta   : state.meta
    }),
    { load, unload, unloadSelectedApp } 
)(withRouter(AppListContainer));
