import React from "react"
import { AppOS, OSRecord } from "../../store/types";


interface OSManagerProps {
    knownOSes: OSRecord[]
    appOSes: AppOS[],
    onChange: (ehrs: AppOS[]) => void
}

interface OSManagerState {
    [key:string]: string
}

export default class OSManager extends React.Component<OSManagerProps>
{
    urls: OSManagerState = {}

    sync()
    {
        this.props.knownOSes.forEach(os => {
            const appOS = this.props.appOSes.find(x => x.operating_system_id === os.id);
            const url = appOS ? appOS.url || "" : "";
            if (url) {
                this.urls[os.id + ""] = url;
            }
        });
    }

    render()
    {
        const { knownOSes, appOSes, onChange } = this.props;
        this.sync();
        return (
            <>
                <label htmlFor="os-support"><h2>Operating System</h2></label>
                <p>Select the operating systems where your app can run. Add an
                app store link for Android and iOS apps.</p>
                <br/>
                <fieldset className="checkbox-rows">
                { knownOSes.map(item => {
                    const appOS = appOSes.find(x => x.operating_system_id === item.id);
                    return (
                        <div key={item.slug} className="checkbox-wrapper">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={ !!appOS }
                                    onChange={e => {
                                        if (e.target.checked) {
                                            onChange([
                                                ...appOSes,
                                                {
                                                    operating_system_id: +item.id,
                                                    url: this.urls[item.id + ""] || ""
                                                }
                                            ]);
                                        } else {
                                            onChange(appOSes.filter(x => x.operating_system_id !== item.id));
                                        }
                                    }}
                                /> { item.name }
                            </label>
                            { (item.slug === 'ios' || item.slug === 'android') && 
                                <input
                                    type="url"
                                    data-os-id={ item.id } // needed for testing
                                    disabled={ !appOS }
                                    placeholder="Link to store"
                                    value={ appOS ?
                                        appOS.url || this.urls[item.id + ""] || "" :
                                        this.urls[item.id + ""] || ""
                                    }
                                    onChange={ e => {
                                        this.urls[item.id + ""] = e.target.value;
                                        if (appOS) {
                                            appOS.url = e.target.value;
                                            onChange(appOSes);
                                        }
                                    }}
                                />
                            }
                        </div>
                    );
                })}
                </fieldset>
            </>
        )
    }
}
