import React from "react"


export default class Footer extends React.Component
{
    render()
    {
        return (
            <footer className="main-footer">
                <div className="container">
                    <div className="left">
                        <a target="_blank" rel="noreferrer" href="http://smarthealthit.org/">SMART Health IT Project</a>
                        <a target="_blank" rel="noreferrer" href="http://smarthealthit.org/an-app-platform-for-healthcare/about/">What are SMART Apps?</a>
                        <a target="_blank" rel="noreferrer" href="https://www.hl7.org/fhir/">What is FHIR?</a>
                        <a href="mailto:gallery@smarthealthit.org?subject=Contact">Contact Us: gallery@smarthealthit.org</a>
                    </div>
                    <div className="right">
                        <a target="_blank" rel="noreferrer" href="/documents/privacy.pdf">Privacy</a>
                        <a target="_blank" rel="noreferrer" href="/documents/terms.pdf">Terms of Use</a>
                        <a target="_blank" rel="noreferrer" href="/documents/disclaimers.pdf">Disclaimer</a>
                    </div>
                </div>
            </footer>
        )
    }
}
