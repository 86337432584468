import React  from "react"
import config from "../../config"
import { AppDemoType } from "../../store/types";

let popup: Window | null;

interface PatientPickerProps {
    /**
     * Comma-separated list of pre-selected patient IDs
     */
    value?: string

    /**
     * The FHIR version to use
     */
    stu: AppDemoType

    onChange: (selection: string) => any

    disabled?: boolean
}

interface FHIRSettings {
    launcher: string
    versionNumber: number
    tags: any[]
    patientPicker: {
        origin: string
        path  : string
        width : number
        height: number
        selectedTags: string[]
    }
}

export default class PatientPicker extends React.Component<PatientPickerProps>
{
    static defaultProps = {
        value: "",
        disabled: false
    };

    selectPatients(selection: string, stu = 'STU-3'): Promise<string>
    {
        // @ts-ignore
        const settings: FHIRSettings | undefined = config.fhir[stu];
        if (!settings) {
            return Promise.reject(new Error(`Invalid FHIR version "${stu}"`));
        }
        return new Promise(function(resolve, reject) {
            const origin = "https://patient-browser.smarthealthit.org";
            let url = origin + settings.patientPicker.path + '#/';
            let paramLength = 0;
            const params = [];

            const onMessage = function onMessage(e: MessageEvent) {
                if (e.origin === origin) {
                    if (e.data.type === 'result' || e.data.type === 'close') {
                        window.removeEventListener('message', onMessage);
                        (popup as Window).close();
                        resolve(e.data.data);
                    }
                }
            };

            if (selection) {
                paramLength = params.push('_selection=' + encodeURIComponent(selection));
            }

            if (settings.patientPicker.selectedTags.length) {
                paramLength = params.push(
                    'tags=' + encodeURIComponent(
                        settings.patientPicker.selectedTags.map(t => t.trim()).join(',')
                    )
                );
            }

            paramLength = params.push('ts=' + Date.now());

            if (paramLength) {
                url += '?' + params.join('&');
            }

            if (popup && !popup.closed) {
                popup.close();
            }

            setTimeout(() => {
                popup = window.open(url, 'picker', [
                    'height=' + settings.patientPicker.height,
                    'width=' + settings.patientPicker.width,
                    'menubar=0',
                    'resizable=1',
                    'status=0',
                    // eslint-disable-next-line no-restricted-globals
                    'top=' + (screen.height - settings.patientPicker.height) / 2,
                    // eslint-disable-next-line no-restricted-globals
                    'left=' + (screen.width - settings.patientPicker.width) / 2,
                ].join(','));

                if (!popup) {
                    return reject(new Error("PatientPicker popup window blocked!"));
                }

                window.addEventListener('message', onMessage);

                window.addEventListener('unload', () => {
                    if (popup && !popup.closed) {
                        popup.close();
                    }
                });
            }, 100);
        });
    }

    onClick(event: React.MouseEvent) {
        event.preventDefault();
        if (!this.props.disabled) {
            const selection = this.props.value || "";
            this.selectPatients(selection, this.props.stu).then(sel => {
                if (sel || sel === '') { // ignore cancel and close cases
                    this.props.onChange(sel);
                }
            }).catch(console.error);
        }
    }

    render()
    {
        const count = String(this.props.value || "").split(/\s*,\s*/).filter(Boolean).length;
        const msg = count === 1 ? '1 patient selected' : `${count} patients selected`;
        return (
            <div className="input-group">
                <div className="patient-picker form-control">
                { msg }
                </div>
                <button
                className="btn btn-brand-2"
                disabled={ this.props.disabled }
                type="button"
                onClick={ this.onClick.bind(this) }
                >Select</button>
            </div>
        );
    }
}

