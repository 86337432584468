import React from "react"
import { Link } from "react-router-dom"
import AppIcon from "../AppIcon"
import LinkList from "../LinkList"
import {
    App,
    DesignedForRecord,
    OSRecord,
    SpecialtyRecord,
    User
} from "../../store/types";

export interface ListEntryProps {
    app: App
    designedFor: DesignedForRecord[]
    specialties: SpecialtyRecord[]
    os: OSRecord[]
    user: User | null
}

export default class ListEntry extends React.PureComponent<ListEntryProps>
{
    renderDesignedFor()
    {
        const { designed_for_id } = this.props.app;
        if (designed_for_id) {
            const rec = this.props.designedFor.find(x => x.id === designed_for_id);
            if (rec) {
                return (
                    <div>
                        <b>Designed for:</b>
                        <Link to={`/apps/designed_for/${rec.slug}`}>{ rec.name }</Link>
                    </div>
                )
            }
        }
        return null
    }

    renderSpecialties()
    {
        const { specialty_ids = [] } = this.props.app;
        if (!specialty_ids ||  !specialty_ids.length) {
            return null
        }

        const appSpecialties = specialty_ids.map(
            id => this.props.specialties.find(x => x.id === id)
        ).filter(Boolean) as SpecialtyRecord[];

        if (!appSpecialties.length) {
            return null
        }

        return (
            <div>
                <b>Specialties:</b>
                <span>
                    <LinkList links={ appSpecialties.map(s => ({
                        to: `/apps/specialty/${s.slug}`,
                        text: s.name
                    }))} />
                </span>
            </div>
        );
    }

    renderOS()
    {
        interface OS {
            slug: string
            name: string
        }
        const availableOperatingSystems = this.props.os || [];
        const appOperatingSystems = this.props.app.operating_systems || [];
        const os = appOperatingSystems.map(os => {
            const osRecord = availableOperatingSystems.find(x => x.id === os.operating_system_id);
            return {
                slug: osRecord?.slug,
                name: osRecord?.name
            };
        }).filter(Boolean) as OS[];

        if (!os.length) {
            return null
        }

        return (
            <div>
                <b>OS:</b>
                <span><LinkList links={ os.map(s => ({
                    to: `/apps/os/${s.slug}`,
                    text: s.name
                })) }/></span>
            </div>
        );
    }

    render()
    {
        const { app/*, user*/ } = this.props;
        const {
            name,
            org_name,
            org_description,
            description_short,
            icon,
            slug,
            status,
            updated_at,
            created_at
        } = app;

        const updatedAt = new Date(updated_at || created_at).toLocaleDateString();
        
        // TODO: Fix this when BE is ready!
        let canEdit = !!app.user_actions?.can_edit;
        
        return (
            <div className="app-list-entry card">
                <div className="app-list-entry-body">
                    <Link to={`/app/${slug}`}><AppIcon icon={ icon } name={name} /></Link>
                    <div className="buttons">
                        { canEdit && <Link to={`/app/${slug}/edit`} className="btn btn-brand-3">Edit</Link> }
                        <Link to={`/app/${slug}`} className="btn btn-brand-3">View</Link>
                    </div>
                    <h3><Link to={`/app/${slug}`}>{ name }</Link> {
                        canEdit && <span className="badge">{ status }</span>
                    }</h3>
                    <h4 title={ org_description || undefined }>{ org_name }</h4>
                    <div className="description">{ description_short }</div>
                </div>
                <footer>
                    { this.renderOS() }
                    { this.renderSpecialties() }
                    { this.renderDesignedFor() }
                    { canEdit && <div><b>Last Updated:</b> { updatedAt }</div> }
                </footer>
            </div>
        )
    }
}
