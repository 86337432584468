import { FormEvent, useState } from "react"
import { useLocation }         from "react-router-dom";


export default function Login(props: { redirect?: string })
{
    const [ loading  , setLoading   ] = useState(false)
    const [ email    , setEmail     ] = useState("")
    const [ emailCode, setEmailCode ] = useState("")
    const [ error    , setError     ] = useState<Error | string | null>(null)

    const { pathname, search } = useLocation()
    const searchParams = new URLSearchParams(search);

    const emailOnly = searchParams.get("type") === "email"

    let redirect = (
        props.redirect ||
        searchParams.get("redirect") ||
        (pathname === "/user/login" ? "/" : pathname) ||
        "/"
    );

    const query = new URLSearchParams()
    query.set("redirect", redirect)

    const action = searchParams.get("action")
    if (action) {
        query.set("action", action)
    }

    const force = searchParams.get("force")
    if (force) {
        query.set("force", force)
    }
    
    function sendEmail(e: FormEvent) {
        e.preventDefault();
        if (!email.trim()) {
            return false
        }
        
        setLoading(true)

        fetch(`/auth/email`, {
            method: `POST`,
            body: JSON.stringify({
              // `destination` is required.
              destination: email,
              // However, you can POST anything in your payload and it will show up in your verify() method
              redirect,
              connect: action === "connect",
              force
            }),
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => res.json())
            .then(json => {
                if (json.success) {
                    // The request successfully completed and the email to the user with the
                    // magic login link was sent!
                    // You can now prompt the user to click on the link in their email
                    // We recommend you display json.code in the UI (!) so the user can verify
                    // that they're clicking on the link for their _current_ login attempt
                    setEmailCode(json.code)
                } else {
                    setError("No verification code generated")
                }
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }

    if (error) {
        return (
            <div className="card">
                <div className="card-body text-center">
                    <h1>Login Error</h1>
                    <hr />
                    <pre className="fg-brand-5">
                        { String(error) }
                    </pre>
                </div>
            </div>
        )
    }

    if (emailCode) {
        return (
            <div className="card">
                <div className="card-body text-center">
                    <h1>Please check your email</h1>
                    <hr />
                    <p>
                        We sent a magic link to <b>{email}</b>.<br/>
                        Click on it to confirm the log in.
                    </p>
                    <p className="fg-grey-3">
                        Verification code: <b>{emailCode}</b>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="need-login card">
            <div className="card-body text-center">
                <h1 className="text-center">Login{ loading && <> <i className="fas fa-spinner fa-spin"/></>}</h1>
                <p className="fg-brand-5 text-center">
                    { emailOnly ?
                        "Please login using an existing email (or create one to use with the gallery)." :
                        "Please login using an existing account or email (or create one to use with the gallery)." }
                </p>
                <br/>
                <form onSubmit={sendEmail}>
                    <div className="input-group" style={{ maxWidth: "45em", margin: "auto" }}>
                        <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} disabled={loading} autoComplete="email" />
                        <button type="submit" className="btn btn-brand-3 margin" style={{ minWidth: "14em" }} disabled={loading}>
                            <b><i className="fas fa-envelope"/>&nbsp; Login with Email</b>
                        </button>
                    </div>
                </form>
                { emailOnly ? <br/> :
                    <>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td><hr/></td>
                                    <td className="fg-grey-3" style={{ width: "3em" }}><b>OR</b></td>
                                    <td><hr/></td>
                                </tr>
                            </tbody>
                        </table>
                        <a href={`/auth/twitter?${query}`} className={ "btn btn-brand-2 margin" + (loading ? " disabled" : "") } style={{ minWidth: "14em" }}>
                            <b><i className="fab fa-twitter"/>&nbsp; Login with Twitter</b>
                        </a>
                        {/* <a href={`/auth/google?${query}`} className={ "btn btn-brand-2 margin" + (loading ? " disabled" : "") } style={{ minWidth: "14em" }}>
                            <b><i className="fab fa-google"/>&nbsp; Login with Google</b>
                        </a> */}
                        <a href={`/auth/github?${query}`} className={ "btn btn-brand-2 margin" + (loading ? " disabled" : "") } style={{ minWidth: "14em" }}>
                            <b><i className="fab fa-github"/>&nbsp; Login with GitHub</b>
                        </a>
                        
                    </>
                }
            </div>
            <footer className="text-center">
                { emailOnly ? 
                    "Your email will ONLY be used for authentication." :
                    "Your account information will ONLY be used for authentication." }
            </footer>
        </div>
    )
}