import React         from "react"
import { Link }      from "react-router-dom"
import { Helmet }    from "react-helmet"
import AppIcon       from "../AppIcon"
import TextWithLinks from "../TextWithLinks"
import TryAppButton  from "../TryAppButton"
import SlideShow     from "../SlideShow/SlideShow"
import LinkList      from "../LinkList"
import {showContactDialog} from "../ContactDialog"
import {
    App,
    CategoryRecord,
    SpecialtyRecord,
    FHIRRecord,
    PricingRecord,
    EHRRecord,
    OSRecord
} from "../../store/types"
import { MetaState } from "../../store/meta"


export interface AppViewProps {
    app: Partial<App>
    exitPreview?: () => void
    meta: MetaState
}

export default class AppView extends React.Component<AppViewProps>
{
    renderDesignedFor()
    {
        const { designed_for_id } = this.props.app;
        if (designed_for_id) {
            const rec = this.props.meta.designedFor.find(x => x.id === designed_for_id);
            if (rec) {
                return (
                    <section>
                        <hr className="small"/>
                        <h4>Designed for</h4>
                        <p><Link to={`/apps/designed_for/${rec.slug}`}>{rec.name}</Link></p>
                    </section>
                )
            }
        }
        return null
    }

    renderApplicationType()
    {
        const { application_type_id } = this.props.app;
        if (application_type_id) {
            const rec = this.props.meta.applicationType.find(x => x.id === application_type_id);
            if (rec) {
                return (
                    <section>
                        <hr className="small"/>
                        <h4>Application Type</h4>
                        <p><Link to={`/apps/type/${rec.slug}`}>{rec.name} App</Link></p>
                    </section>
                )
            }
        }
        return null
    }

    renderCategories()
    {
        const { category_ids = [] } = this.props.app;
        if (!category_ids || !category_ids.length) {
            return null
        }

        const categories = category_ids.map(
            id => this.props.meta.categories.find(c => c.id === id)
        ).filter(Boolean) as CategoryRecord[];

        if (!categories.length) {
            return null
        }

        return (
            <section>
                <hr className="small"/>
                <h4>Categories</h4>
                <p><LinkList links={ categories.map(item => ({
                    to: `/apps/category/${item.slug}`,
                    text: item.name
                }))}/></p>
            </section>
        );
    }

    renderSpecialties()
    {
        const { specialty_ids = [] } = this.props.app;
        if (!specialty_ids || !specialty_ids.length) {
            return null
        }

        const appSpecialties = specialty_ids.map(
            id => this.props.meta.specialties.find(x => x.id === id)
        ).filter(Boolean) as SpecialtyRecord[];

        if (!appSpecialties.length) {
            return null
        }

        return (
            <section>
                <hr className="small"/>
                <h4>Specialties</h4>
                <p><LinkList links={ appSpecialties.map(item => ({
                    to: `/apps/specialty/${item.slug}`,
                    text: item.name
                }))}/></p>
            </section>
        );
    }

    renderFhirCompatibility()
    {
        const { fhir_ids = [] } = this.props.app;
        if (!fhir_ids || !fhir_ids.length) {
            return null
        }

        const appFhir = fhir_ids.map(
            id => this.props.meta.fhir.find(x => x.id === id)
        ).filter(Boolean) as FHIRRecord[];

        if (!appFhir.length) {
            return null
        }
        
        return (
            <section>
                <hr className="small"/>
                <h4>FHIR Compatibility</h4>
                <p><LinkList links={ appFhir.map(item => ({
                    to: `/apps/fhir/${item.slug}`,
                    text: item.name
                }))}/></p>
            </section>
        );
    }

    renderPricings()
    {
        const { pricing_ids = [], pricing_description } = this.props.app;
        const appPricing = pricing_ids.map(
            id => this.props.meta.pricing.find(x => x.id === id)
        ).filter(Boolean) as PricingRecord[];

        if (!appPricing.length && !pricing_description) {
            return null
        }
        
        return (
            <section>
                <hr className="small"/>
                <div>
                    <div>
                        <h4>Licensing & Pricing</h4>
                        { appPricing.length > 0 && <p><LinkList links={ appPricing.map(item => ({
                            to: `/apps/pricing/${item.slug}`,
                            text: item.name
                        }))}/></p> }
                        { pricing_description && <TextWithLinks>{pricing_description || ""}</TextWithLinks> }
                    </div>
                </div>
            </section>
        )
    }

    renderEHR()
    {
        const { ehrs } = this.props.app;

        if (!ehrs || !ehrs.length) {
            return null
        }

        const { ehr } = this.props.meta;

        const appEHRs = ehrs.map(
            o => {
                const ehrRecord = ehr.find(x => x.id === o.ehr_id) as EHRRecord;
                return {
                    url_pattern: ehrRecord.url_pattern,
                    name: ehrRecord.name,
                    id: ehrRecord.id,
                    url: o.url,
                    slug: ehrRecord.slug
                }
            }
        ).filter(Boolean);

        return (
            <section>
                <hr className="small"/>
                <h4>EHR Support</h4>
                <p className="ehr-list">{appEHRs.map((item, i) => (
                    <span key={item.id}>
                        { i > 0 ? <span>, </span> : null }
                        <Link className="underline" to={`/apps/ehr/${item.slug}`}>{item.name}</Link>
                        { item.url && <a className="ehr-link" href={item.url} target="_blank" rel="noreferrer">
                            <i className="fas fa-external-link-alt"/>
                        </a> }
                    </span>
                ))}</p>
            </section>
        )
    }

    renderOS()
    {
        const { operating_systems } = this.props.app;

        if (!operating_systems || !operating_systems.length) {
            return null
        }

        const { operatingSystems: os } = this.props.meta;

        const appOS = operating_systems.map(x => {
            const osRecord = os.find(o => o.id === x.operating_system_id) as OSRecord;
            return {
                url : x.url,
                slug: osRecord.slug,
                name: osRecord.name
            };
        });

        return (
            <section>
                <hr className="small"/>
                <h4>OS Support</h4>
                <p className="os-list">{appOS.map((item, i) => (
                    <span key={item.slug}>
                        { i > 0 ? <span>, </span> : null }
                        <Link className="underline" to={`/apps/os/${item.slug}`}>{item.name}</Link>
                        { item.url && <a className="os-link" href={item.url} target="_blank" rel="noreferrer">
                            <i className="fas fa-external-link-alt"/>
                        </a> }
                    </span>
                ))}</p>
            </section>
        )
    }

    render()
    {
        const { app, exitPreview, meta: { auth: { user }} } = this.props;
    
        // Note that this component is also being used to preview an app that
        // is currently being created. This means any app property might be
        // missing and that is why we explicitly set some default values here
        const {
            id,
            url,
            name,
            slug,
            icon,
            org_name,
            description,
            description_short,
            screenshot_1,
            screenshot_2,
            screenshot_3,
            demo_launch_url,
            demo_patient_ids,
            demo_type,
            video_url,
            has_sales_contact,
            user_actions
        } = app;

        const encodedOrgName = encodeURIComponent(org_name || "");

        const canEdit = !!user_actions?.can_edit;

        return (
            <>
                { exitPreview && <div className="preview-banner">
                    <span>Preview</span>
                    <button type="button" className="btn btn-brand-2" onClick={() => exitPreview()}>Exit Preview</button>
                </div> }
                <div className="card app-view">
                    <Helmet>
                        <title>{ name || "No Name" }</title>
                    </Helmet>
                    <AppIcon name={ name || "?" } icon={ icon } />
                    <h1>{ name }</h1>
                    <h3><Link to={`/apps/search?q=${encodedOrgName}&organization=${encodedOrgName}`}>{org_name}</Link></h3>
                    <div className="buttons">
                        { url && <a target="_blank" rel="noreferrer" className="btn btn-brand-2" href={url}>Website</a> }
                        <TryAppButton
                            demo_launch_url={ demo_launch_url || "" }
                            demo_patient_ids={ demo_patient_ids || "" }
                            demo_type={ demo_type || "none" }
                        />
                        { has_sales_contact && <button
                            className="btn btn-brand-2"
                            onClick={() => showContactDialog({
                                title: name,
                                appId: id + "",
                                emailFrom: user ? user.email || undefined : undefined
                            })}
                        >Contact</button> }
                        { canEdit && !exitPreview && <Link className="btn btn-brand-2" to={`/app/${slug}/edit`}>Edit</Link> }
                    </div>
                    <hr/>
                    <div className="grid-container">
                        <section className="slideshow-col">
                            <SlideShow video={video_url} screenshots={[ screenshot_1, screenshot_2, screenshot_3 ]}/>
                        </section>
                        <section>
                            <TextWithLinks className="description">{ description || description_short || "" }</TextWithLinks>
                        </section>
                        <section>
                            <div className="grid-container">
                                { this.renderDesignedFor() }
                                { this.renderApplicationType() }
                            </div>
                        </section>
                        <section>
                            <div className="grid-container">
                                { this.renderFhirCompatibility() }
                                { this.renderEHR() }
                            </div>
                        </section>
                        { this.renderCategories() }
                        { this.renderSpecialties() }
                        { this.renderPricings() }
                        { this.renderOS() }
                    </div>
                    <br/>
                    <br/>
                    <hr className="small"/>
                    <a target="_top" href="mailto:gallery@smarthealthit.org?subject=Listing Report">
                        <i className="fas fa-exclamation-circle" /> Report Listing
                    </a>
                </div>
            </>
        )
    }
}
