import { NotAuthenticatedError, NotAuthorizedError, NotFoundError } from "../errors";
import Error403 from "./Error403";
import Error404 from "./Error404";
import Login    from "../Login";

export default function ErrorMessage({ error }: { error: Error }) {

    if (error instanceof NotAuthorizedError) {
        return <Error403/>
    }

    if (error instanceof NotFoundError) {
        return <Error404/>
    }

    if (error instanceof NotAuthenticatedError) {
        return <Login/>
    }

    return <pre style={{ whiteSpace: "pre-wrap" }}>{ String(error) }</pre>
}