import React from "react"
import { NavLink } from "react-router-dom"
import {
    AppsFilterType,
    AppsSortType,
    AssociationRecord,
    CategoriesState,
    DesignedForState,
    EHRState,
    FHIRState,
    OSState,
    PricingState,
    SpecialtiesState,
    ApplicationTypeState,
    User
} from "../../store/types";

interface SidebarProps {
    filterType     ?: AppsFilterType
    filterValue    ?: any
    sort           ?: AppsSortType
    categories      : CategoriesState
    designedFor     : DesignedForState
    specialties     : SpecialtiesState
    pricing         : PricingState
    os              : OSState
    fhir            : FHIRState
    ehr             : EHRState
    applicationTypes: ApplicationTypeState
    user           ?: User | null
}

interface SidebarState {
    category    : boolean
    os          : boolean
    fhir        : boolean
    specialty   : boolean
    pricing     : boolean
    designed_for: boolean
    ehr         : boolean
    featured    : boolean
    type        : boolean
}

export default class Sidebar extends React.Component<SidebarProps, SidebarState>
{
    constructor(props: SidebarProps)
    {
        super(props);
        this.state = {
            category     : props.filterType === "category" || !props.filterValue,
            os           : props.filterType === "os",
            fhir         : props.filterType === "fhir",
            specialty    : props.filterType === "specialty",
            pricing      : props.filterType === "pricing",
            designed_for : props.filterType === "designed_for",
            ehr          : props.filterType === "ehr",
            featured     : props.filterType === "featured",
            type         : props.filterType === "type"
        };
    }

    linkTo(pathname: string)
    {
        return (loc: {}) => {
            const { sort } = this.props;
            return { ...loc, pathname, search: sort ? `sort=${sort}` : "" };
        };
    }

    renderSection(title: string, filterType: keyof SidebarState, items: AssociationRecord[])
    {
        return (
            <>
                <section className={ this.state[filterType] ? "open" : undefined }>
                    <h3 onClick={() => this.setState({ [filterType]: !this.state[filterType] } as any)}>{title}</h3>
                    { items.map(c => (
                        <NavLink to={ this.linkTo(`/apps/${filterType}/${c.slug}`) } key={`${filterType}-${c.slug}`}>{c.name}</NavLink>
                    )) }
                </section>
            </>
        )
    }

    render()
    {
        const { categories, designedFor, specialties, pricing, os, fhir, ehr, applicationTypes, user } = this.props;

        return (
            <>
                <button
                    className="btn btn-brand-2 sidebar-toggle"
                    onClick={e => e.currentTarget.classList.toggle("visible-sidebar")}
                >
                    <i className="fas fa-bars"/> Filters <i className="fas fa-caret-down"/><i className="fas fa-times" />
                </button>
                <div className="sidebar card" id="#sidebar">
                    <NavLink to={ this.linkTo("/apps/featured") }><b>Featured Apps</b></NavLink>
                    <NavLink
                        to={ this.linkTo("/apps") }
                        isActive={(match, location) => {
                            return !!(match && (/\/apps(\/page\/\d+)?$/).test(location.pathname));
                        }}
                    ><b>All Apps</b></NavLink>
                    { user && user.role === "admin" && <NavLink to={ this.linkTo("/apps/drafts") }><b className="fg-brand-4"><i className="fas fa-user-shield" />Drafts</b></NavLink> }
                    { user && user.role === "admin" && <NavLink to={ this.linkTo("/apps/review") }><b className="fg-brand-4"><i className="fas fa-user-shield" />Review Submissions</b></NavLink> }
                    <br/>
                    { this.renderSection("Application Type", "type", applicationTypes) }
                    { this.renderSection("Categories", "category", categories) }
                    { this.renderSection("OS Support", "os", os) }
                    { this.renderSection("FHIR Support", "fhir", fhir) }
                    { this.renderSection("Specialty", "specialty", specialties) }
                    { this.renderSection("Pricing", "pricing", pricing) }
                    { this.renderSection("Designed for", "designed_for", designedFor) }
                    { this.renderSection("EHR Support", "ehr", ehr) }
                </div>
            </>
        )
    }
}
