import React         from "react"
import { Link, RouteComponentProps, withRouter } from "react-router-dom"
import { connect }   from "react-redux"
import { User }      from "../../store/types";
import { RootState } from "../../store";
import config        from "../../config";
import MenuButton    from "../MenuButton";

interface HeaderProps extends RouteComponentProps {
    user: User | null
}

class Header extends React.Component<HeaderProps>
{
    
    render()
    {
        const { location, user } = this.props;
        const q = new URLSearchParams(location.search).get("q") || "";

        return (
            <nav className="main-header" role="navigation" key={ location.search }>
                <div className="main-header-container container">
                    <Link className="heading" to="/apps/featured">
                        <img title="SMART" alt="SMART App Gallery Logo" className="logo" src="/logo.svg"/>
                        <span>App Gallery</span>
                    </Link>
                    <div className="links">
                        { <Link to="/app/new">Add New Listing</Link> }
                        { <Link to="/user/apps">Your Listings</Link> }
                        {/* { user && user.role === "admin" && <Link to="/apps/review">Review Submissions</Link> } */}
                    </div>
                    <div className="right">
                        <form role="search" method="get" action="/apps/search">
                            <div className="search-field-wrap">
                                <input
                                    type="text"
                                    name="q"
                                    placeholder="Search"
                                    defaultValue={ q }
                                    autoComplete={ config.advancedSearch.enabled ? "off" : undefined }
                                />
                                { config.advancedSearch.enabled && <div className="advanced-search-link-wrap">
                                    <Link to="/apps/advanced-search" onMouseDown={e=>e.preventDefault()}><i className="fas fa-search"/> Advanced search</Link>
                                </div> }
                            </div>
                            <button type="submit" className="btn btn-brand-2">
                                <i className="fas fa-search"/>
                            </button>
                        </form>
                        { user ?
                            <MenuButton label={<><i className="fas fa-user" />&nbsp;▾</>} className="logout-link menu-right">
                                <>
                                    <Link to="/user/account">My Account</Link>
                                    <hr />
                                    <a href="/auth/logout" title={`Logout ${user.username}`}>Logout</a>
                                </>
                            </MenuButton> :
                            <Link to="/user/login" className="logout-link btn btn-brand-2">LogIn</Link>
                        }
                    </div>
                </div>
            </nav>
        )
    }
}

export default connect(
    (state: RootState) => ({
        user: state.meta.auth.user
    })
)(withRouter(Header));