import React            from "react"
import { Helmet }       from "react-helmet"
import { createPortal } from "react-dom"

interface SlideshowProps {
    video: any
    screenshots: any
}

interface SlideshowState {

    /**
     * When true content is previewed on full-screen carousel
     */
    enhance: boolean

    /**
     * The index of the currently active slide. Defaults to 0
     * for the first slide or -1 for empty slideshow
     */
    selectedIndex: number

    slides: slide[]
}

type slide = LocalImageSlide | ImageSlide | YouTubeSlide | VimeoSlide

interface LocalImageSlide {
    service: "data-url"
    dataUrl: string
}

interface ImageSlide {
    service: "image"
    secure_url: string
}

interface YouTubeSlide {
    service: "youtube"
    external_id: string
}

interface VimeoSlide {
    service: "vimeo"
    external_id: string
}

function Slide({ slide, isThumbnail, ...rest }: { slide: slide, [key: string]: any }) {
    if (slide.service === "youtube") {
        return isThumbnail ?
            <img alt="YouTube Video" className="media" src={ `https://img.youtube.com/vi/${slide.external_id}/hqdefault.jpg` } { ...rest } /> :
            <iframe
                width="640"
                height="360"
                frameBorder="0"
                title="YouTube Video"
                src={`//www.youtube.com/embed/${slide.external_id}`}
                className="media"
                { ...rest }
            />;
    }

    if (slide.service === "vimeo") {
        return isThumbnail ?
            <i className="fab fa-vimeo media"/> :
            <iframe
                src={`//player.vimeo.com/video/${slide.external_id}?title=0&byline=0&portrait=0`}
                width="640"
                height="337"
                frameBorder="0"
                title="Vimeo Video"
                allowFullScreen={true}
                className="media"
                { ...rest }
            />
    }

    if (slide.service === "data-url") {
        return <img className="media" alt="Local Upload" src={ slide.dataUrl } { ...rest } />
    }

    return <img className="media" alt="App Attachment" src={slide.secure_url} { ...rest } />
}

function getVideoSlide(url: string)
{
    if (url.match("youtube") || url.match("youtu.be")) {
        const match = /(?:\?v=|^(?:[^/]*\/){3}(?:embed\/|[^?]*\?v=)?)([^/?&#]*)/.exec(url);
        if (!match || !match[1]) {
            console.warn(`Can not understand YouTube video url "${url}"`);
            return null;        
        }
        return {
            external_id: match[1],
            service: "youtube",
        };
    }

    if (url.match("vimeo")) {
        const match = /^(?:[^/]*\/){3}(?:video\/)?([^/?#]*)/.exec(url);
        if (!match || !match[1]) {
            console.warn(`Can not understand Vimeo video url "${url}"`);
            return null;        
        }
        return {
            external_id: match[1],
            service: "vimeo",
        };
    }

    console.warn(`Can not understand video url "${url}"`);
    return null;
}

interface CarouselProps {
    slides: slide[],
    selectedIndex: number
    onChange: (index: number) => void
    onClick?: (e: React.SyntheticEvent) => void
}

/**
 * Renders the given slides in a div that scrolls horizontally and
 * shows only one slide at a time. This is reused for rendering the
 * small embedded stage of slideshows, as well as for the large
 * fullscreen view.
 */
class Carousel extends React.Component<CarouselProps>
{
    stage: HTMLDivElement | null = null;

    scrollX: number = 0;

    isMouseDown: boolean = false;

    isObserving: boolean = false;

    intersectionObserver: IntersectionObserver | null = null;

    constructor(props: CarouselProps)
    {
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp   = this.onMouseUp.bind(this);
        this.onClick     = this.onClick.bind(this);
    }

    // ************************************************************************
    // Custom Methods
    // ************************************************************************

    /**
     *  Scrolls the selected slide into view if needed
     */
    syncScroll()
    {
        const stage = (this.stage as HTMLDivElement);
        if (stage) {
            const x = stage.clientWidth * this.props.selectedIndex;
            if (stage.scrollLeft !== x) {
                stage.scrollLeft = x;
            }
        }
    }

    /**
     * Observes slide positions so that after the the user scrolls (slides)
     * the stage we can detect which slide is being viewed and call the
     * onChange callback if needed.
     */
    observeScrollPositions()
    {
        if (!this.isObserving && this.stage) {
            const stage = (this.stage as HTMLDivElement);
            const slides = stage.querySelectorAll(".slide");
            
            this.intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio === 1) {
                        // @ts-ignore
                        let index = +(entry.target as HTMLDivElement).getAttribute("data-index");
                        if (index !== this.props.selectedIndex) {
                            this.props.onChange(index);
                        }
                    }
                })
            }, {
                root: stage,
                threshold: 1

            });

            slides.forEach(slide => {
                if (this.intersectionObserver) {
                    this.intersectionObserver.observe(slide)
                }
            });

            this.isObserving = true;
        }
    }

    /**
     * Move to the next slide
     */
    next()
    {
        const { selectedIndex, slides, onChange } = this.props;
        if (selectedIndex < slides.length - 1) {
            onChange(selectedIndex + 1);
        }
    }

    prev()
    {
        const { selectedIndex, onChange } = this.props;
        if (selectedIndex > 0) {
            onChange(selectedIndex - 1);
        }
    }

    // ************************************************************************
    // Lifecycle Methods
    // ************************************************************************

    /**
     * Scroll the selected slide into view after the initial render. Then wait
     * a while and start the scroll observer
     */
    componentDidMount()
    {
        setTimeout(() => {
            this.syncScroll();
            setTimeout(() => this.observeScrollPositions(), 1000);
        }, 20);
    }

    /**
     * Scroll the selected slide into view when the selected index changes
     */
    componentDidUpdate(prevProps: CarouselProps)
    {
        if (this.props.selectedIndex !== prevProps.selectedIndex) {
            this.syncScroll();
        }
    }

    /**
     * Remove global event listeners and stop observing scroll positions
     */
    componentWillUnmount()
    {
        window.removeEventListener("mousemove", this.onMouseMove, false);
        window.removeEventListener("mouseup"  , this.onMouseUp  , false);
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }
    }

    // ************************************************************************
    // Event Handlers
    // ************************************************************************

    /**
     * Detect if the user has dragged the slides and if so, stop the click
     * event as it is not really a click any more. Otherwise pass the click
     * event to the onClick callback if one is provided.
     * @param e The click event
     */
    onClick(e: React.MouseEvent)
    {
        const stage = (this.stage as HTMLDivElement);
        if (e.button !== 0 || this.scrollX !== e.pageX + stage.scrollLeft) {
            e.stopPropagation();
            e.preventDefault();
        } else if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    /**
     * This is the starting point of a custom drag-scroll gesture for devices
     * with a mouse
     * @param e The mousedown event
     */
    onMouseDown(e: React.MouseEvent)
    {
        e.preventDefault()
        this.isMouseDown = true;
        const stage = (this.stage as HTMLDivElement);
        this.scrollX = e.pageX + stage.scrollLeft;
        window.addEventListener("mouseup"  , this.onMouseUp  , false);
        window.addEventListener("mousemove", this.onMouseMove, false);
    }

    /**
     * Scroll the stage if it was grabbed with the mouse 
     * @param e The mousemove event
     */
    onMouseMove(e: MouseEvent)
    {
        const stage = (this.stage as HTMLDivElement);
        const movementX = e.pageX - this.scrollX;
        stage.scrollLeft = movementX * -1;
    }

    /**
     * Stop dragging slides
     * @param e The mouseup event
     */
    onMouseUp(e: MouseEvent)
    {
        this.isMouseDown = false;
        window.removeEventListener("mousemove", this.onMouseMove, false);
        window.removeEventListener("mouseup"  , this.onMouseUp  , false);
    }

    // ************************************************************************
    // Rendering Methods
    // ************************************************************************

    renderButtons()
    {
        const { slides, selectedIndex } = this.props;
        return (
            <>
                { selectedIndex > 0 && <div
                    className="prev-link"
                    title="Previous"
                    onClick={ () => this.prev() }
                /> }
                { selectedIndex < slides.length - 1 && <div
                    className="next-link"
                    title="Next"
                    onClick={ () => this.next() }
                /> }
            </>
        )
    }

    render()
    {
        const { slides } = this.props;

        return (
            <>
                <div
                    className="carousel"
                    onMouseDown={ this.onMouseDown }
                    onClick={ this.onClick }
                    ref={ el => this.stage = el }>
                    { slides.map((_, index) => (
                        <div className="slide" key={index} data-index={index}>
                            <Slide slide={slides[index]} />
                        </div>
                    ))}
                </div>
                { this.renderButtons() }
            </>
        );
    }
}

export default class Slideshow extends React.Component<SlideshowProps, SlideshowState>
{
    stage: HTMLDivElement | null = null;

    scrollX: number = 0;
    stageLeft: number = 0;
    isScrolling: number = 0;

    constructor(props: SlideshowProps)
    {
        super(props);

        const slides = [
            this.props.video ? getVideoSlide(this.props.video) : null,
            ...this.props.screenshots
        ].filter(Boolean).filter(x => x.service);

        this.state = {
            enhance: false,
            selectedIndex: slides.length ? 0 : -1,
            slides
        };

        this.toggleEnhance    = this.toggleEnhance.bind(this);
        this.setSelectedIndex = this.setSelectedIndex.bind(this);
        this.next             = this.next.bind(this);
        this.prev             = this.prev.bind(this);
        this.onKeyDown        = this.onKeyDown.bind(this);
    }    

    componentDidMount()
    {
        window.addEventListener("keydown", this.onKeyDown, false);
    }

    componentWillUnmount()
    {
        window.removeEventListener("keydown", this.onKeyDown, false);
    }

    toggleEnhance(ev: React.SyntheticEvent | Event)
    {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({ enhance: !this.state.enhance });
    }
  
    setSelectedIndex(ev: React.SyntheticEvent, selectedIndex: number)
    {
        ev.preventDefault();
        this.setState({ selectedIndex });
    }

    next(e: React.SyntheticEvent | Event)
    {
        let i = this.state.selectedIndex + 1;
        if (i < this.state.slides.length) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ selectedIndex: i });
        }
    }

    prev(e: React.SyntheticEvent | Event)
    {
        let i = this.state.selectedIndex - 1;
        if (i >= 0) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ selectedIndex: i });
        }
    }

    onKeyDown(event: KeyboardEvent|React.KeyboardEvent)
    {
        if (!this.state.enhance) {
            return true;
        }
        switch (event.key) {
            case "Escape": // Esc
                this.toggleEnhance(event);
            break;
            case "ArrowRight":
                this.next(event);
            break;
            case "ArrowLeft":
                this.prev(event);
            break;
            default:
            break;
        }
    }

    render()
    {
        const { selectedIndex: activeIndex, slides, enhance } = this.state;

        let popUpWrapper;
        try {
            popUpWrapper = document.getElementById("pop-up-wrapper");
        } catch {}

        return (
            <div className="slideshow">
                { enhance && <Helmet><body className="has-popup" /></Helmet>}
                <div className="stage">
                    <Carousel
                        slides={slides}
                        selectedIndex={activeIndex}
                        onChange={ (selectedIndex: number) => this.setState({ selectedIndex })}
                        onClick={ this.toggleEnhance }
                    />
                </div>
                { slides.length > 1 && <div className="thumbnails">
                     { slides.map((_, index) => (
                        <div
                            className={"slide" + (index === activeIndex ? " active" : "")}
                            onClick={ e => this.setSelectedIndex(e, index) }
                            key={index}
                        >
                            <Slide slide={slides[index]} isThumbnail />
                        </div>
                    ))}
                </div> }
                { enhance && popUpWrapper && createPortal(
                    <div className="expanded-image-wrapper">
                        <div className="stage">
                            <Carousel
                                slides={slides}
                                selectedIndex={activeIndex}
                                onChange={ (selectedIndex: number) => this.setState({ selectedIndex })}
                                onClick={ this.toggleEnhance }
                            />
                            <b className="close-button" title="Close Preview" onClick={ this.toggleEnhance }>
                                <i className="fas fa-times-circle" />
                            </b>
                        </div>
                    </div>,
                    popUpWrapper
                ) }
            </div>
        );
    }
}
