import React            from "react"
import { connect }      from "react-redux"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"
import { Helmet }       from "react-helmet"
import Form             from "../components/ApplicationForm/ApplicationForm"
import { save, unload } from "../store/selectedApp"
import { RootState }    from "../store"
import { MetaState }    from "../store/meta"
import { NotAuthenticatedError, NotAuthorizedError, NotFoundError } from "../errors"
import Error403 from "../components/Error403"
import Error404 from "../components/Error404"
import Login    from "../Login"

interface AddApplicationProps extends RouteComponentProps {
    save    : () => void
    unload  : () => void
    error  ?: Error | null
    loading?: boolean
    saved  ?: boolean
    meta    : MetaState
}

class AddApplication extends React.Component<AddApplicationProps>
{
    componentWillUnmount()
    {
        this.props.unload()
    }

    render()
    {
        const { meta, save, loading, error, saved } = this.props;

         // Exit early on some errors (don't render the form in that case)
         if (error) {
            if (error instanceof NotAuthorizedError) {
                // TODO: Handle 403 after submit for review
                return <Error403/>
            }

            if (error instanceof NotFoundError) {
                return <Error404/>
            }

            if (error instanceof NotAuthenticatedError) {
                return <Login/>
            }
        }

        if (saved && !error) {
            return <Redirect to={{ pathname: "/user/apps", state: { saved: true }}} />
        }

        return (
            <>
                <Helmet>
                    <title>Add Application</title>
                </Helmet>
                <Form
                    app={{}}
                    save={save}
                    loading={loading}
                    error={error}
                    meta={meta}
                />
            </>
        );
    }
}

export default connect(
    (state: RootState) => ({
        meta   : state.meta,
        error  : state.selectedApp.error,
        loading: state.selectedApp.loading,
        saved  : state.selectedApp.saved
    }),
    { save, unload }
)(withRouter(AddApplication));
