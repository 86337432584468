import React         from "react"
import { Link }      from "react-router-dom"
import Select        from "../Select/index"
import MultiSelect   from "../MultiSelect/MultiSelect"
import AppIcon       from "../AppIcon"
import PatientPicker from "../PatientPicker/index"
import TryAppButton  from "../TryAppButton"
import ViewApp       from "../AppView/index"
import ImageManager  from "../ImageManager/ImageManager"
import UserManager   from "../UserManager/index"
import EHRManager    from "../EHRManager/index"
import OSManager     from "../OSManager/index"
import { isEmptyObject, humanFileSize } from "../../lib"
import config        from "../../config"
import {
    App,
    AppImageDescriptor,
    AppUser,
    CategoryRecord,
    FHIRRecord,
    LocalImageDescriptor,
    PricingRecord,
    RemoteImageDescriptor,
    SpecialtyRecord,
    User
} from "../../store/types"
import Loader from "../Loader"
import { MetaState } from "../../store/meta"

const RE_URL = /^((http[s]?):\/)\/([^:/\s.]+(\.[^:/\s.]+)+)(:\d+)?((\/\w+)*(\/[\w\-.]+[^#?\s]+|\/)?)(\?.*)?(#[\w-]+)?$/;

export interface ApplicationFormProps {
    app  : Partial<App>
    save : (app: Partial<App>) => any
    error?: Error | null
    loading?: boolean
    meta: MetaState
}

interface ApplicationFormState {
    app: Partial<App>
    errors: ApplicationFormErrors
    preview: boolean
}

interface ApplicationFormErrors {
    application_type?: string
    categories?: string
}

export default class ApplicationForm extends React.Component<ApplicationFormProps, ApplicationFormState>
{
    constructor(props: ApplicationFormProps)
    {
        super(props);

        this.state = {
            app: { ...this.props.app },
            errors: {},
            preview: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    static getDerivedStateFromProps(props: ApplicationFormProps, state: ApplicationFormState) {
        // console.log(`getDerivedStateFromProps`, props, state)
        return { app: { ...props.app, ...state.app }};
    }

    submit(state: Partial<App> = {}, noValidate = false)
    {
        if (!noValidate) {
            const form = document.getElementById("app-form") as HTMLFormElement;
            if (form && form.reportValidity && !form.reportValidity()) {
                return;
            }
        }

        if (this.state.app.status !== "submitted" && state.status === "submitted") {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm("This will submit your app listing for review by the SMART Health IT administrators.\n\n" +
                "Submitting your app listing for review will lock in your submission and you will not be able " +
                "to make further edits until review is complete.\n\n" +
                "Are you sure you want to submit?")) {
                return;
            }
        }

        const json = { ...this.state.app, ...state };

        // Filter out images having validation errors
        ["screenshot_1", "screenshot_2", "screenshot_3", "icon"].forEach(name => {
            // @ts-ignore
            const img = json[name] as AppImageDescriptor;
            if (img && img.errors && img.errors.length) {
                // @ts-ignore
                delete json[name];
            }
        });

        delete json.user_actions;

        this.props.save(json);
    }

    onSubmit(e: React.FormEvent<HTMLFormElement>)
    {
        e.preventDefault();
        this.submit({}, true);
    }

    setFormState(state: Partial<App>)
    {
        this.setState({
            app: {
                ...this.state.app,
                ...state
            }
        });
    }

    componentDidUpdate(prevProps: ApplicationFormProps)
    {
        // If we got a new error from the server scroll to it
        if (this.props.error && this.props.error !== prevProps.error) {
            document.querySelector(".error-wrapper")?.scrollIntoView({ behavior: "smooth" });
        }
    }

    // Rendering methods -------------------------------------------------------

    renderTryAppButton()
    {
        const {
            demo_type = "",
            demo_launch_url = "",
            demo_redirect_url,
            demo_patient_ids
        } = this.state.app;

        if (!demo_type || demo_type === "none" || !demo_launch_url) {
            return null;
        }

        return (
            <div className="col-12 form-group">
                <label><h2>Test Demo</h2></label>
                <p>Test your app launch with sample data before submitting this form!</p>
                <p>
                    <TryAppButton
                        demo_launch_url={ demo_launch_url }
                        demo_patient_ids={ demo_patient_ids || "" }
                        demo_type={ demo_type }
                        disabled={
                            !RE_URL.test(demo_launch_url) ||
                            !RE_URL.test(demo_redirect_url || "")
                        }
                    >Test Demo</TryAppButton>
                </p>
            </div>
        );
    }

    renderButtons()
    {
        const { user }     = this.props.meta.auth;
        const { app }      = this.state;
        const { status     = "draft", slug, is_featured } = app;
        const isAdmin      = user && user.role === "admin";

        // NOTE: The server decides who can do what!
        // The default values here will only apply while new App is being created
        const {
            can_approve      = false,
            can_delete       = false,
            can_set_featured = isAdmin,
            can_submit       = true,
            can_unpublish    = false,
            can_edit         = !app.id
        } = app.user_actions || {};

        return (
            <div className="form-buttons">
                
                { can_set_featured && <label className="set-featured"><input type="checkbox"
                    checked={ !!is_featured }
                    onChange={e => this.setFormState({
                        is_featured: e.target.checked
                    })} /> Feature Listing
                </label> }

                { can_delete && <Link
                    to={`/app/${slug}/delete`}
                    className="btn btn-danger delete-btn"
                    title="Delete this app and its associated data"
                >Delete</Link> }

                <button
                    type="button"
                    className="btn btn-brand-2"
                    title="See how this app listing would look when published"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        this.setState({ preview: true });
                    }}
                >Preview</button>

                { can_edit && <button
                    type="submit"
                    className="btn btn-brand-3 save-btn"
                    title={ status === "draft" ?
                        "Save changes and remain in draft status" :
                        app.id ?
                            "Save changes to this listing" :
                            "Create new app listing"
                    }
                >{ status === "draft" ? "Save Draft" : "Save" }</button> }

                { can_submit && <button
                    type="button"
                    className="btn btn-brand-3 publish-btn"
                    onClick={() => this.submit({ status: "submitted" })}
                    title="Your listing will be reviewed by administrators and then listed in the gallery"
                >Submit for Review</button> }

                { can_unpublish && <button
                    type="button"
                    className="btn btn-brand-3 unpublish-btn"
                    onClick={() => this.submit({ status: "draft" })}
                    title="The listing will be switched to a draft state so that changes can be made without people seeing them"
                >Switch to Draft</button> }

                { can_approve && <button
                    type="button"
                    className="btn btn-success publish-btn"
                    onClick={() => this.submit({ status: "published" })}
                    title="Make the app visible to everyone"
                >Publish</button> }
            </div>
        );          
    }

    render()
    {
        const {
            loading,
            error,
            meta
        } = this.props;

        const {
            designedFor,
            applicationType: appTypes,
            categories,
            pricing,
            specialties,
            ehr,
            operatingSystems: os,
            fhir,
            auth
        } = meta;

        const { app, preview, errors } = this.state;
        const user = auth.user as User;

        const isAdmin = user && user.role === "admin";

        if (preview) {
            return <ViewApp
                meta={meta}
                app={ app }
                exitPreview={ () => {
                    window.scrollTo(0, 0);
                    this.setState({ preview: false });
                }}
            />
        }

        const appCategories = (app.category_ids || []).map(
            id => categories.find(c => c.id === id)
        ).filter(Boolean) as CategoryRecord[];

        const appSpecialties = (app.specialty_ids || []).map(
            id => specialties.find(x => x.id === id)
        ).filter(Boolean) as SpecialtyRecord[];

        const appFhir = (app.fhir_ids || []).map(
            id => fhir.find(x => x.id === id)
        ).filter(Boolean) as FHIRRecord[];

        const appPricing = (app.pricing_ids || []).map(
            id => pricing.find(x => x.id === id)
        ).filter(Boolean) as PricingRecord[];

        const appUsers: AppUser[] = app.users || [];
        if (!app.id && !appUsers.find(u => u.role === "author")) {
            appUsers.push({ username: user?.username, role: "author" });
        }

        const healthCardsAppTypeId = appTypes.find(x => x.slug === "health-cards")?.id;

        const isHealthCardsApp = healthCardsAppTypeId && healthCardsAppTypeId === app.application_type_id;

        return (
            <div className={ "app-form card" + (loading ? " loading" : "")}>
                <form onSubmit={this.onSubmit} id="app-form">
                    <header>
                        <h1>{ app.id ? "Edit" : "Add" } Listing</h1>
                        { isAdmin && app.status && <span className="badge">{ app.status }</span> }
                        <span className="flex1"/>
                        { this.renderButtons() }
                    </header>

                    { loading && <Loader>Loading...</Loader> }

                    { error && <div className="error-wrapper"><div className="errors save-errors">{
                    String(error).replace(/^Error:\s*/, "").split(/\s*;\s*/).map((x, i) => (
                        <div key={`error-${i}`}><i className="fas fa-times-circle"/> {x}</div>
                    )) }</div></div> }

                    <p><span className="fg-brand-4">*</span> Required fields</p>
                    <br />
                    { isEmptyObject(errors) ? null : (
                        <div className="errors">
                            { Object.values(errors).map((x, i) => <div key={i}>{x}</div>)}
                        </div>
                    )}

                    <div>
                        <div className="col-12 form-group">
                            <label htmlFor="application-type">
                                <h2>Application Type <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Start by selecting your application type. Based on that, we will provide other fields for you to fill in.</p>
                            <Select
                                id="application-type"
                                name="application_type"
                                required
                                options={ appTypes.map(x => ({ label: x.name + " App", value: x })) }
                                value={ appTypes.find(x => x.id === app.application_type_id) }
                                onChange={ option => this.setFormState({ application_type_id: option.id })}
                            />
                        </div>
                        <br/>
                    </div>

                    <div>
                        <div className="col-6 form-group">
                            <label htmlFor="application-name">
                                <h2>App Name <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Maximum of 140 characters</p>
                            <input
                                id="application-name"
                                type="text"
                                name="application-name"
                                required
                                minLength={ 3 }
                                maxLength={ 140 }
                                value={ app.name || "" }
                                onChange={ e => this.setFormState({ name: e.target.value }) }
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="app-website">
                                <h2>App Website <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>URL for your app’s website</p>
                            <input
                                id="app-website"
                                type="url"
                                name="url"
                                placeholder="http://website.com"
                                required
                                maxLength={ 140 }
                                value={ app.url || "" }
                                onChange={ e => this.setFormState({ url: e.target.value }) }
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="organization-name">
                                <h2>Organization Name <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Maximum of 140 characters </p>
                            <input
                                id="organization-name"
                                type="text"
                                name="organization"
                                autoComplete="organization"
                                required
                                minLength={ 3 }
                                maxLength={ 140 }
                                value={ app.org_name || "" }
                                onChange={ e => this.setFormState({ org_name: e.target.value }) }
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="categories">
                                <h2>Categories <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Select up to 3 categories</p>
                            <MultiSelect
                                id="categories"
                                name="categories"
                                maxSelections={3}
                                required
                                values={ appCategories.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                options={ categories.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                onChange={ categories => this.setFormState({ category_ids: categories.map(c => c.id) }) }
                                error="Please select up to 3 categories"
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="app-short-description">
                                <h2>App Short Description <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Maximum of 140 characters</p>
                            <textarea
                                id="app-short-description"
                                rows={ 5 }
                                name="description_short"
                                minLength={ 3 }
                                maxLength={ 140 }
                                required
                                value={ app.description_short || "" }
                                onChange={ e => this.setFormState({ description_short: e.target.value }) }
                            />
                        </div>

                    </div>

                    <div className="form-group">
                        <label htmlFor="app-long-description">
                            <h2>App Description <span className="fg-brand-4">*</span></h2>
                        </label>
                        <p>
                            Minimum of 140 characters. If your app is patient facing,
                            consider creating a <a className="fg-brand-4" target="_blank" rel="noreferrer" href="https://www.healthit.gov/policy-researchers-implementers/model-privacy-notice-mpn">
                            Model Privacy Notice</a> and linking to it in the description below.
                        </p>
                        <textarea
                            id="app-long-description"
                            rows={ 10 }
                            name="description"
                            required
                            minLength={ 140 }
                            maxLength={ 5000 }
                            value={ app.description || "" }
                            onChange={ e => this.setFormState({ description: e.target.value }) }
                        />
                    </div>

                    <div>
                        <div className="col-6 form-group">
                            <label htmlFor="upload-screenshot">
                                <h2>Screenshots <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Upload at least one screenshot image</p>
                            <ImageManager
                                id="upload-screenshot"
                                minLength={1}
                                maxLength={3}
                                accept={ config.uploads.acceptedMimeTypes }
                                values={(function() {
                                    const out = [];
                                    if (app.screenshot_1) out.push(app.screenshot_1);
                                    if (app.screenshot_2) out.push(app.screenshot_2);
                                    if (app.screenshot_3) out.push(app.screenshot_3);
                                    return out;
                                })()}
                                onChange={values => this.setFormState({
                                    screenshot_1: values[0] || null,
                                    screenshot_2: values[1] || null,
                                    screenshot_3: values[2] || null
                                })}
                                validate={(img: RemoteImageDescriptor | LocalImageDescriptor) => {
                                    const errors: string[] = [];
                                    const { maxUploadBytes, acceptedMimeTypes } = config.uploads;
                                    if (img.service === "data-url") {
                                        if (maxUploadBytes < img.size) {
                                            errors.push(`The file size is greater than our ${humanFileSize(maxUploadBytes)} limit`);
                                        }
                                        if (!acceptedMimeTypes.find(a => img.format.toLowerCase() === a.toLowerCase())) {
                                            errors.push(`Unsupported file type "${img.format}". We support ${acceptedMimeTypes.join(", ")}.`);
                                        }
                                    }
                                    return errors;
                                }}
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="application-icon">
                                <h2>Icon</h2>
                            </label>
                            <p>Upload a square icon for your app (minimum 256px x 256px)</p>
                            <ImageManager
                                id="application-icon"
                                minLength={0}
                                maxLength={1}
                                accept={ config.uploads.acceptedMimeTypes }
                                values={app.icon ? [app.icon] : []}
                                onChange={ values => this.setFormState({ icon: values[0] || null }) }
                                defaultImage={ <AppIcon name={ app.name || "" } /> }
                                validate={(img: RemoteImageDescriptor | LocalImageDescriptor) => {
                                    const errors: string[] = [];
                                    const { maxUploadBytes, acceptedMimeTypes } = config.uploads;
                                    if (img.service === "data-url") {
                                        if (maxUploadBytes < img.size) {
                                            errors.push(`The file size is greater than our ${humanFileSize(maxUploadBytes)} limit`);
                                        }
                                        if (!acceptedMimeTypes.find(a => img.format.toLowerCase() === a.toLowerCase())) {
                                            errors.push(`Unsupported file type "${img.format}". We support ${acceptedMimeTypes.join(", ")}.`);
                                        }
                                    }
                                    if (img.width && img.height) {
                                        if (img.width !== img.height) {
                                            errors.push(`The app icon must be a square image.`);
                                        }
                                        if (img.width < 256 || img.height < 256) {
                                            errors.push(`Please select an image which is at least 256 x 256 pixels`);
                                        }
                                    }
                                    return errors;
                                }}
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="embed-video"><h2>Video</h2></label>
                            <p>Enter a YouTube or Vimeo URL to embed a video</p>
                            <input
                                id="embed-video"
                                type="url"
                                name="video_url"
                                value={ app.video_url || "" }
                                onChange={ e => this.setFormState({ video_url: e.target.value }) }
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="designed-for">
                                <h2>Designed For <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Select your app's primary audience</p>
                            <Select
                                id="designed-for"
                                name="designed_for"
                                required
                                value={ designedFor.find(x => x.id === app.designed_for_id) }
                                options={ designedFor.map(x => ({ value: x, label: x.name })) }
                                onChange={ option => this.setFormState({ designed_for_id: option.id }) }
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="pricing"><h2>Pricing</h2></label>
                            <p>Select up to 3 pricing models</p>
                            <MultiSelect
                                id="pricing"
                                name="pricing"
                                maxSelections={3}
                                values={ appPricing.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                options={ pricing.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                onChange={ pricings => {
                                    this.setFormState({ pricing_ids: pricings.map(x => x.id) });
                                }}
                            />
                            <p><label htmlFor="pricing_description">Pricing Details</label></p>
                            <textarea
                                rows={3}
                                name="pricing_description"
                                id="pricing_description"
                                value={ app.pricing_description || undefined }
                                onChange={ e => this.setFormState({ pricing_description: e.target.value }) }
                            />
                        </div>

                        {isHealthCardsApp ? null : (<div className="col-6 form-group">
                            <label htmlFor="clinical-specialties"><h2>Clinical Specialties</h2></label>
                            <p>Select up to 3 clinical specialties</p>
                            <MultiSelect
                                id="clinical-specialties"
                                name="specialties"
                                maxSelections={3}
                                values={ appSpecialties.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                options={ specialties.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                onChange={ specialties => this.setFormState({ specialty_ids: specialties.map(s => s.id) }) }
                            />
                        </div>)}
                    </div>                   

                    <h1 className="section-header">Contacts</h1>

                    <div>
                        <div className="col-6 form-group">
                            <label htmlFor="administrative-contact">
                                <h2>Administrative Contact <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>
                                Enter an email address for us to contact you with
                                questions about this listing. This email address
                                will not be shared publicly.
                            </p>
                            <input
                                id="administrative-contact"
                                type="email"
                                name="email"
                                required
                                value={ app.email || "" }
                                onChange={ e => this.setFormState({ email: e.target.value }) }
                            />
                        </div>

                        <div className="col-6 form-group">
                            <label htmlFor="sales-contact"><h2>Sales Contact</h2></label>
                            <p>
                                Enter an email address for us to forward questions about the app
                                from users browsing the gallery. This email address will not be
                                shared publicly.
                            </p>
                            <input
                                id="sales-contact"
                                type="email"
                                name="sales_contact"
                                value={ app.sales_contact || "" }
                                onChange={ e => this.setFormState({ sales_contact: e.target.value }) }
                            />
                        </div>
                    </div>

                    { (!app.id || app.user_actions?.can_set_editors) && <>
                    <h1 className="section-header">Access</h1>
                    <div>
                        <div className="col-6 form-group">
                            <UserManager users={appUsers} onChange={users => this.setFormState({ users })} />
                        </div>
                    </div></> }

                    <h1 className="section-header">Support</h1>
                    <div>
                        {isHealthCardsApp ? null : (
                        <div className="col-6 form-group">
                            <EHRManager
                                knownEHRs={ehr}
                                appEHRs={ this.state.app.ehrs || [] }
                                onChange={ehrs => this.setFormState({ ehrs })}
                            />
                        </div>)}

                        <div className="col-6 form-group">
                            <OSManager
                                knownOSes={ os }
                                appOSes={ this.state.app.operating_systems || [] }
                                onChange={ operating_systems => this.setFormState({ operating_systems })}
                            />
                        </div>

                        {isHealthCardsApp ? null : (
                        <div className="col-6 form-group">
                            <label htmlFor="fhir-version-support">
                            <h2>FHIR Support <span className="fg-brand-4">*</span></h2>
                            </label>
                            <p>Select the FHIR versions your app works with</p>
                            <MultiSelect
                                id="fhir-version-support"
                                name="fhir"
                                values={ appFhir.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                options={ fhir.map(c => ({ value: c, label: c.name, id: c.slug })) }
                                onChange={ fhirs => { this.setFormState({ fhir_ids: fhirs.map(x => x.id) }); }}
                                required
                            />
                        </div>)}
                    </div>

                    {isHealthCardsApp ? null : (
                        <>
                            <h1 className="section-header">Demo</h1>

                            <p>
                                Configure the OAuth settings to launch your app with sample data
                                from the SMART sandbox. Completing this information will enable the
                                “Try App” button in the app’s listing.
                                <br/>
                                <br/>
                                <i><b>NOTE: </b> <small className="fg-grey-4">This section is for SMART apps that use
                                the <a className="fg-brand-4" href="http://www.hl7.org/fhir/smart-app-launch/#ehr-launch-sequence" target="_blank" rel="noreferrer">
                                EHR launch sequence</a>, which means that these apps can be launched by any EHR, including
                                the <a className="fg-brand-4" href="https://launch.smarthealthit.org/" target="_blank" rel="noreferrer">testing one</a> used by the
                                SMART App Gallery. For <a className="fg-brand-4" href="http://www.hl7.org/fhir/smart-app-launch/#standalone-launch-sequence" target="_blank" rel="noreferrer">
                                standalone apps</a> you can set the "Demo Type" to "None" and put your launch URL in the "App Website" field above. Then users will
                                be able to launch your app by clicking on the "Website" button.</small></i>
                            </p>

                        
                            <div>
                                <div className="col-6 form-group">
                                    <label htmlFor="demo-type"><h2>Demo Type</h2></label>
                                    <p>Select a demo type from the following list</p>
                                    <Select
                                        id="demo-type"
                                        name="demo_type"
                                        options={[
                                            { value: 'none', label: 'None' },
                                            ...Object.keys(config.fhir).map(
                                                (key) => ({
                                                    value: key,
                                                    // @ts-ignore
                                                    label: config.fhir[key].label
                                                })
                                            )
                                        ]}
                                        value={String(app.demo_type || "none")}
                                        onChange={demo_type => {
                                            this.setFormState({ demo_type });
                                        }}
                                    />
                                </div>

                                <div className="col-6 form-group">
                                    <label htmlFor="launch-url">
                                        <h2>Launch URL <span className="fg-brand-4">*</span></h2>
                                    </label>
                                    <p>The app's OAuth Launch URL</p>
                                    <input
                                        type="url"
                                        id="launch-url"
                                        name="demo_launch_url"
                                        placeholder="http://website.com/launch"
                                        value={ app.demo_launch_url || "" }
                                        onChange={ e => this.setFormState({ demo_launch_url: e.target.value }) }
                                        required={ !!(app.demo_type && app.demo_type !== "none") }
                                        disabled={ !app.demo_type || app.demo_type === "none" }
                                    />
                                    <input type="hidden" name="demo_requires_patient"/>
                                </div>

                                <div className="col-6 form-group">
                                    <label><h2>Patients</h2></label>
                                    <p>
                                    To restrict the patient picker to a subset of the SMART sample
                                    patients, select the appropriate patients here. Use the
                                    "Test Demo" button below to see how your app works with those
                                    patients.
                                    </p>
                                    <PatientPicker
                                        value={ app.demo_patient_ids || "" }
                                        stu={ app.demo_type || "none" }
                                        onChange={ sel => this.setFormState({ demo_patient_ids: sel })}
                                        disabled={ !app.demo_type || app.demo_type === "none" }
                                    />
                                </div>

                                <div className="col-6 form-group">
                                    <label htmlFor="redirect-url">
                                        <h2>Redirect URL <span className="fg-brand-4">*</span></h2>
                                    </label>
                                    <p>The app's OAuth Redirect URL</p>
                                    <input
                                        type="url"
                                        id="redirect-url"
                                        name="demo_redirect_url"
                                        placeholder="http://website.com/start"
                                        required={ !!(app.demo_type && app.demo_type !== "none") }
                                        disabled={ !app.demo_type || app.demo_type === "none" }
                                        value={ app.demo_redirect_url || "" }
                                        onChange={ e => this.setFormState({ demo_redirect_url: e.target.value }) }
                                    />
                                </div>

                                { this.renderTryAppButton() }
                            </div>
                        </>
                    )}
                    
                    <footer>
                        <p className="flex1">
                            Questions? <a
                                target="_top"
                                href="mailto:gallery@smarthealthit.org?subject=App%20Form"
                            >gallery@smarthealthit.org</a>
                        </p>
                        { this.renderButtons() }
                    </footer>

                </form>
            </div>
        );
    }
}
