import React          from "react"
import { connect }    from "react-redux"
import Select         from "../Select/index"
import { search }     from "../../store/apps"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { RootState } from "../../store/index"
import { MetaState } from "../../store/meta"


interface iAdvancedSearchFormProps extends RouteComponentProps {
    meta  : MetaState
    search: (state: iAdvancedSearchFormState) => any;
}

interface iAdvancedSearchFormState {
    q          : string;
    category   : string;
    designedFor: string;
    specialty  : string;
    os         : string;
    fhir       : string;
    pricing    : string;
    ehr        : string;
    type       : string;
    sort       : string;
    perPage    : string;
}


export class AdvancedSearchForm extends React.Component<iAdvancedSearchFormProps, iAdvancedSearchFormState>
{
    constructor(props: iAdvancedSearchFormProps)
    {
        super(props);

        this.state = {
            q          : "",
            category   : "",
            designedFor: "",
            specialty  : "",
            os         : "",
            fhir       : "",
            pricing    : "",
            ehr        : "",
            type       : "",
            sort       : "name-asc",
            perPage    : "10"
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount()
    {
        const q = new URLSearchParams(window.location.search);

        this.setState({
            q          : q.get("q") || "",
            category   : q.get("category") || "",
            designedFor: q.get("designedFor") || "",
            specialty  : q.get("specialty") || "",
            os         : q.get("os") || "",
            fhir       : q.get("fhir") || "",
            pricing    : q.get("pricing") || "",
            ehr        : q.get("ehr") || "",
            type       : q.get("type") || "",
            sort       : q.get("sort") || "name-asc",
            perPage    : q.get("perPage") || "10"
        });
    }

    onSubmit(e: React.FormEvent)
    {
        e.preventDefault();

        const { location, history } = this.props;
        const q = new URLSearchParams(location.search);

        q.set("q"          , this.state.q          );
        q.set("sort"       , this.state.sort       );
        q.set("perPage"    , this.state.perPage    );
        q.set("category"   , this.state.category   );
        q.set("designedFor", this.state.designedFor);
        q.set("os"         , this.state.os         );
        q.set("fhir"       , this.state.fhir       );
        q.set("pricing"    , this.state.pricing    );
        q.set("ehr"        , this.state.ehr        );
        q.set("type"       , this.state.type       );

        history.push({ search  : "?" + q });

        this.props.search(this.state);
    }

    render()
    {
        const {
            categories,
            designedFor,
            specialties,
            operatingSystems: os,
            fhir,
            pricing,
            ehr
        } = this.props.meta;

        return (
            <form className="advanced-search-form app-list-entry card" onSubmit={ this.onSubmit }>
                <div className="app-list-entry-body">
                    <h3>Advanced Search</h3>
                    <div>
                        <div className="flex-row">
                            <label className="flex-col-2 fg-grey-4">Contains text: </label>
                            <div className="flex-col-8">
                                <input
                                    name="q"
                                    type="text"
                                    placeholder="Searches in titles and description"
                                    value={ this.state.q }
                                    onChange={ e => this.setState({ q: e.target.value }) }
                                />
                            </div>
                        </div>
                        <div className="flex-row">
                            <label className="flex-col-2 fg-grey-4">Category</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.category }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        ...categories.map(x => ({ value: x.slug, label: x.name }))
                                    ]}
                                    onChange={ category => this.setState({ category }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">Designed for</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.designedFor }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        ...designedFor.map(x => ({ value: x.slug, label: x.name }))
                                    ]}
                                    onChange={ designedFor => this.setState({ designedFor }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">Specialty</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.specialty }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        ...specialties.map(x => ({ value: x.slug, label: x.name }))
                                    ]}
                                    onChange={ specialty => this.setState({ specialty }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">EHR Support</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.ehr }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        ...ehr.map(x => ({ value: x.slug, label: x.name }))
                                    ]}
                                    onChange={ ehr => this.setState({ ehr }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">OS Support</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.os }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        ...os.map(x => ({ value: x.slug, label: x.name }))
                                    ]}
                                    onChange={ os => this.setState({ os }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">Pricing</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.pricing }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        ...pricing.map(x => ({ value: x.slug, label: x.name }))
                                    ]}
                                    onChange={ pricing => this.setState({ pricing }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">FHIR Version</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.fhir }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        ...fhir.map(x => ({ value: x.slug, label: x.name }))
                                    ]}
                                    onChange={ fhir => this.setState({ fhir }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">App Type</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.type }
                                    emptyValue="Any"
                                    options={[
                                        { value: "", label: "Any" },
                                        { value: "smart", label: "SMART Apps" },
                                        { value: "bulk-data", label: "Bulk Data Apps" }
                                    ]}
                                    onChange={ type => this.setState({ type }) }
                                />
                            </div>
                        </div> 
                        <div className="flex-row">
                            <label className="flex-col-2 fg-grey-4">Sort by</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.sort }
                                    options={[
                                        { value: "name-asc", label: "Name (A-Z)" },
                                        { value: "name-desc", label: "Name (Z-A)" },
                                        { value: "created-desc", label: "Date Created" },
                                        { value: "updated-desc", label: "Date Updated" },
                                        { value: "rank", label: "Relevance" }
                                    ]}
                                    onChange={ sort => this.setState({ sort }) }
                                />
                            </div>

                            <label className="flex-col-2 fg-grey-4">Apps per page</label>
                            <div className="flex-col-3">
                                <Select
                                    value={ this.state.perPage }
                                    options={[
                                        { value: "10", label: "10" },
                                        { value: "20", label: "20" },
                                        { value: "30", label: "30" },
                                        { value: "40", label: "40" },
                                        { value: "50", label: "50" }
                                    ]}
                                    onChange={ perPage => this.setState({ perPage }) }
                                />
                            </div>
                        </div>               
                    </div>
                </div>
                <footer className="text-center">
                    <button type="submit" className="btn btn-brand-1" style={{ padding: "12px 48px"}}>Search</button>
                </footer>
            </form>
        )
    }
}

export default connect(
    (state: RootState) => ({ meta: state.meta }),
    { search }
)(withRouter(AdvancedSearchForm));
