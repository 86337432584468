import React            from "react"
import ReactGA          from "react-ga"
import { Switch, Route, Redirect } from "react-router-dom";
import Header           from "../components/Header/index"
import Footer           from "../components/Footer/index"
import EditApplication  from "./EditApplication"
import AddApplication   from "./AddApplication"
import View             from "./ViewApp"
import AppList          from "./AppList"
import MyApps           from "./MyApps"
import RequireLogin     from "./RequireLogin"
import Error404         from "../components/Error404"
import ConfirmAppDelete from "../components/ConfirmAppDelete"
import config           from "../config"
import Account          from "../components/Account"
import AuthError        from "../components/AuthError"
import Login            from "../Login";


const shouldUseGA = process.env.NODE_ENV !== "test" && config.GATrackingId;
if (shouldUseGA) {
  ReactGA.initialize(config.GATrackingId);
}


export default class App extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="main container">
          <Switch>
            <Redirect from="/" to="/apps/featured" exact />
            {/* <Redirect from="/login" to="/user/login" exact /> */}

            <Route path={[
              "/apps/:filterType/:filterValue/page/:page",
              "/apps/:filterType/page/:page",
              "/apps/page/:page",
              "/apps/:filterType/:filterValue",
              "/apps/:filterType",
              "/apps"
            ]}>
              <AppList />
            </Route>

            <Route path="/app/new">
              <RequireLogin>
                <AddApplication />
              </RequireLogin>
            </Route>

            <Route path="/app/:slug" exact>
              <View />
            </Route>

            <Route path="/app/:slug/edit">
              <RequireLogin>
                <EditApplication />
              </RequireLogin>
            </Route>

            <Route path="/app/:slug/delete">
              <RequireLogin>
                <ConfirmAppDelete />
              </RequireLogin>
            </Route>

            <Route path="/user/login">
              <RequireLogin />
            </Route>

            <Route path="/login">
              <Login />
            </Route>

            <Route path="/user/apps">
              <RequireLogin>
                <MyApps />
              </RequireLogin>
            </Route>

            <Route path="/user/account">
              <RequireLogin>
                <Account />
              </RequireLogin>
            </Route>

            <Route path="/auth-error">
              <AuthError />
            </Route>

            <Route>
              <Error404 />
            </Route>

          </Switch>
        </div>
        <Footer />
        <Route exact={false} sensitive={false} strict={false} render={({ location }) => {
          if (shouldUseGA) {
            setTimeout(() => {
              const page = location.pathname + location.search + location.hash;
              ReactGA.set({ page });
              ReactGA.pageview(page);
            }, 1000)
          }
          return null
        }} />
      </>
    );
  }
}
