import { Dispatch } from "redux"
import { ThunkAction } from "redux-thunk"
import { RootState } from "./index"
import { apiRequest } from "../lib"
import {
    AppsState,
    MergeAppsActionPayload,
    AppsFilterType,
    AppsSortType,
    MergeAppsAction,
    AppsSearchOptions,
    MERGE_APPS
} from "./types";

interface LoadJob {
    abortController: AbortController
    job: Promise<any>
}

let loadJob: LoadJob | null = null;

const initialState: AppsState = {
    loading: true,
    error: null,
    items: [],
    page: 1,
    perPage: 25,
    totalCount: 0,
    pageCount: 1,
    count: 0,
    sort: "name-asc",
    uri: ""
};


export function merge(payload: MergeAppsActionPayload) {
    return { type: MERGE_APPS, payload };
}

function buildApiUri({
    filterType,
    filterValue,
    page,
    sort,
    q,
    organization
}: {
    filterType: AppsFilterType,
    filterValue: any,
    page: string|number,
    sort: AppsSortType
    q: string,
    organization?: string
})
{
    // /api/v1/apps[/filterType[/filterValue]][?[sort=sort]&[q=q][&page=page]]
    let path = ["/apps"];

    if (filterType) {
        path.push(filterType);
        if (filterValue) {
            path.push(filterValue);
        }
    }

    const query = new URLSearchParams();

    if (page) {
        query.set("page", page + "");
    }
    if (sort) {
        query.set("sort", sort);
    }
    if (q && filterType === "search") {
        query.set("q", q);
    }
    if (organization && filterType === "search") {
        query.set("organization", organization);
    }

    let uri = path.join("/");
    let qs = query.toString();
    if (qs) {
        uri += "?" + decodeURIComponent(qs);
    }

    return uri;
}

// TODO: This is incomplete
export function search(
    {q,category,designedFor,os,fhir,pricing,ehr,type,sort,perPage}: AppsSearchOptions
): ThunkAction<any, RootState, unknown, any>
{
    const query = new URLSearchParams();

    query.set("q"          , q          );
    query.set("category"   , category   );
    query.set("designedFor", designedFor);
    query.set("os"         , os         );
    query.set("fhir"       , fhir       );
    query.set("pricing"    , pricing    );
    query.set("ehr"        , ehr        );
    query.set("type"       , type       );
    query.set("sort"       , sort       );
    query.set("perPage"    , perPage    );

    const uri = `/apps/search?${query}`;

    return function (dispatch, getState) {
        const { apps } = getState();
        if (encodeURI(uri) !== apps.uri) {
            dispatch(merge({ loading: true, uri }));
            apiRequest(uri).then(({ data, metadata }) => {
                dispatch(merge({
                    ...metadata,
                    items  : data,
                    error  : null,
                    loading: false
                }))
            }).catch(error => {
                dispatch(merge({
                    error,
                    loading: false
                }));
            });
        }
    }
}

export function load(options: any): ThunkAction<any, RootState, unknown, any>
{
    return function (dispatch: Dispatch, getState: () => any)
    {
        function doFetch() {
            loadJob = {
                abortController: new AbortController(),
                job: Promise.resolve()
            };
            dispatch(merge({ loading: true, uri }));
            loadJob.job.then(() => apiRequest(uri, {
                signal: loadJob?.abortController.signal
            }).then(({ data, metadata }) => {
                dispatch(merge({
                    ...metadata,
                    items  : data,
                    error  : null,
                    loading: false
                }))
            }).catch(error => {
                if (error.name === "AbortError") {
                    // console.warn(error.message);
                    return dispatch(merge({ loading: false }));
                }
                dispatch(merge({ error, loading: false }));
            }));
            return loadJob;
        }

        const { apps } = getState();
        let uri = buildApiUri(options);

        if (uri !== apps.uri) {
            if (apps.loading && loadJob) {
                loadJob.job.catch(doFetch)
                loadJob.abortController.abort()
            } else {
                loadJob = doFetch()
            }
        }
    };
}


export function unload()
{
    return { type: MERGE_APPS, payload: { ...initialState, loading: false } };
}

export default function appsReducer(state = initialState, action: MergeAppsAction)
{
    switch (action.type) {
        case MERGE_APPS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
