import React from "react"
import { AppImageDescriptor, LocalImageDescriptor } from "../../store/types"
import { humanFileSize } from "../../lib"


function getHumanizedImageType(format: string) {
    format = format.toLowerCase();
    const map = {
        "jpg"          : "JPEG",
        "image/jpg"    : "JPEG",
        "jpeg"         : "JPEG",
        "image/jpeg"   : "JPEG",
        "png"          : "PNG",
        "image/png"    : "PNG",
        "gif"          : "GIF",
        "image/gif"    : "GIF",
        "webp"         : "WEBP",
        "image/webp"   : "WEBP",
        "ico"          : "Icon",
        "image/vnd.microsoft.icon": "Icon",
        "svg"          : "SVG",
        "image/svg+xml": "SVG"
    };
    let key: keyof typeof map;
    for (key in map) {
        if (format === key) {
            return map[key];
        }
    }
    return "Unknown Format";
}

interface ImageManagerProps {

    /**
     * How many images are required
     */
    minLength?: number

    /**
     * How many images are allowed
     */
    maxLength?: number

    values: AppImageDescriptor[]

    onChange: (values: AppImageDescriptor[]) => any

    /**
     * Default image to be rendered when no image has been selected
     */
    defaultImage?: JSX.Element

    accept?: string[]

    maxBytes?: number

    validate?: (descriptor: AppImageDescriptor) => string[]

    /**
     * The ID of the file input
     */
    id?: string
}

export default class ImageManager extends React.Component<ImageManagerProps>
{
    getValues(): AppImageDescriptor[]
    {
        return this.props.values.filter(x => x && x.service);
    }

    onFileSelected(e: React.ChangeEvent<HTMLInputElement>)
    {
        const file = (e.target.files as FileList)[0];
        const reader = new FileReader();
        
        reader.addEventListener("load", () => {
            const img = new Image();
            const descriptor: LocalImageDescriptor = {
                dataUrl: reader.result as string,
                service: "data-url",
                size: file.size,
                format: file.type
            };

            img.onload = () => {
                descriptor.width  = img.naturalWidth;
                descriptor.height = img.naturalHeight;

                if (this.props.validate) {
                    descriptor.errors = this.props.validate(descriptor);
                }
                
                this.props.onChange([
                    ...this.getValues(),
                    descriptor
                ]);
            };

            img.src = reader.result as string;
        }, false);
        
        reader.readAsDataURL(file);
    }

    makePrimary(index: number)
    {
        const values = this.getValues();
        values.unshift(values.splice(index, 1)[0]);
        this.props.onChange(values);
    }

    remove(index: number)
    {
        const values = this.getValues();
        values.splice(index, 1);
        this.props.onChange(values);
    }

    renderThumbnails()
    {
        const {
            maxLength = 1,
            defaultImage
        } = this.props;
        const values = this.getValues();
        const thumbs = [];


        for (let i = 0; i < maxLength; i++) {
            const img = values[i];
            if (!img) {
                thumbs.push(
                    <div key={i} className="screenshot">
                        <div className="screenshot-wrapper">
                            { defaultImage || <i className="fas fa-camera"/> }
                        </div>
                    </div>
                );
            } else {
                thumbs.push(
                    <div key={i} className={"screenshot" + (img.service === "data-url" && img.errors && img.errors.length ? " has-error" : "")}>
                        <div className="screenshot-wrapper">
                            <i className="fas fa-times-circle del-btn" title="Remove" onClick={() => this.remove(i)}/>
                            <img src={ img.service === "data-url" ? img.dataUrl : img.secure_url } alt={`Screenshot ${i}`}/>
                        </div>
                        <div className="meta">{img.width} x {img.height}</div>
                        <div className="meta">
                            {img.service === "data-url" && humanFileSize(img.size)} {getHumanizedImageType(img.format)}
                        </div>
                        { maxLength > 1 && <footer>
                            { maxLength > 1 && i === 0 && <b className="badge">Primary</b> }
                            { maxLength > 1 && i > 0 && <button type="button" className="btn btn-brand-2"
                                onClick={() => this.makePrimary(i)}>Make Primary</button> }
                        </footer> }
                    </div>
                );
            }
        }

        return (
            <div className="image-manager-thumbnails">{ thumbs }</div>
        );
    }

    render()
    {
        const {
            minLength = 0,
            maxLength = 1,
            accept = ["image/png","image/jpeg"],
            id
        } = this.props;
        const values = this.getValues();

        return (
            <div className="image-manager">
                <input
                    type="file"
                    id={id}
                    accept={accept.join(",")}
                    disabled={ values.length >= maxLength }
                    required={ values.length < minLength }
                    onChange={ e => this.onFileSelected(e) }
                    value=""
                />
                { this.renderThumbnails() }
                { values.map((img, i) => {
                    if (img.service === "data-url" && img.errors && img.errors.length) {
                        return (
                            <ul className="error-message" key={`messages-${i}`}>
                                { maxLength > 1 && <b>Image {i+1}:</b> }
                                {img.errors.map((e, y) => (
                                    <li key={`message-${i}.${y}`}><i className="fas fa-exclamation-triangle"/>{e}</li>
                                ))}
                            </ul>
                        );
                    }
                    return null;
                })}
            </div>
        );
    }
}