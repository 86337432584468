import React from "react"

export interface iSelectOption {
    value: any;
    label: string;
    description?: string;
}

export interface iSelectProps {
    value      ?: any;
    onChange   ?: (value:any) => any;
    valuePrefix?: string;
    emptyValue ?: string;
    options     : iSelectOption[]
    [key: string]: any
}

export interface iSelectState {
    hasFocus: boolean;
    highlightedIndex: number;
}


export default class Select extends React.Component<iSelectProps, iSelectState>
{
    static defaultProps = {
        options: [],
        value: null
    };

    input: HTMLInputElement | null = null;

    constructor(props: iSelectProps)
    {
        super(props);

        // bind methods with variable context
        this.onFocus   = this.onFocus.bind(this);
        this.onBlur    = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.state = {
            hasFocus: false,
            highlightedIndex: this.getSelectedIndex()
        };
    }

    componentDidUpdate(prevProps: iSelectProps, prevState: iSelectState): void {
        const highlightedIndex = this.getSelectedIndex(this.props);
        if (highlightedIndex !== this.getSelectedIndex(prevProps)) {
            this.setState({ highlightedIndex });
        }
    }

    onChange(value: any)
    {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    // Event handlers ----------------------------------------------------------

    /**
     * Sets this.state.hasFocus to true which will open the menu
     */
    onFocus(): void {
        this.setState({ hasFocus: true });
    }

    /**
     * Sets this.state.hasFocus to false which will close the menu
     */
    onBlur(): void {
        this.setState({ hasFocus: false });
    }

    /**
     * Close the menu and call the callback (if any).
     * @param option The option object corresponding to the clicked div
     * @returns {void}
     */
    onOptionClick(option: iSelectOption): void {
        setTimeout(() => (this.input as HTMLInputElement).blur());
        if (option.value !== this.props.value) {
            this.onChange(option.value);
        }
    }

    onKeyDown(event: React.KeyboardEvent) {
        switch (event.key) {
            case "Enter":
                if (this.state.hasFocus) {
                    event.preventDefault();
                    if (this.state.highlightedIndex >= 0) {
                        const selectedOption = this.props.options[this.state.highlightedIndex];
                        if (selectedOption) {
                            if (selectedOption.value !== this.props.value) {
                                this.onBlur();
                                this.onChange(selectedOption.value);
                            }
                        }
                    }
                }
            break;
            case "Escape":
                event.preventDefault();
                if (this.state.hasFocus) {
                    (this.input as HTMLInputElement).blur();
                }
            break;
            case "ArrowDown":
                event.preventDefault();
                if (!this.state.hasFocus) {
                    return this.setState({ hasFocus: true });
                }
                const len = this.props.options.length;
                if (this.state.highlightedIndex < len - 1) {
                    this.setState({
                        highlightedIndex: this.state.highlightedIndex + 1
                    });
                }
            break;
            case "ArrowUp":
                event.preventDefault();
                if (this.state.highlightedIndex > 0) {
                    this.setState({
                        highlightedIndex: this.state.highlightedIndex - 1
                    });
                }
            break;
            default:
            break;
        }

        return true;
    }

    // Private helpers ---------------------------------------------------------

    /**
     * Returns the index of the currently selected option (might be -1)
     * @param props The props to use (defaults to the current props)
     * @returns The selected index
     */
    getSelectedIndex(props: iSelectProps = this.props): number {
        return props.options.findIndex(o => props.value === o.value);
    }

    // Rendering methods -------------------------------------------------------

    renderOptions() {
        if (!this.state.hasFocus) {
            return null;
        }
        let hasSelection = false;
        const options = this.props.options.map((option, i) => {
            let className = "select-option";
            let labelPrefix = "";

            if (this.state.highlightedIndex === i) {
                className += " highlighted";
            }

            if (!hasSelection && this.props.value === option.value) {
                hasSelection = true;
                className += " selected";
                labelPrefix = "✔︎";
            }

            return (
                <div
                    className={className}
                    key={i}
                    onMouseDown={this.onOptionClick.bind(this, option)}
                >
                    <span className="select-label-prefix">{labelPrefix}</span>{ option.label }
                    {option.value.description && <div className="select-option-description">{option.value.description}</div>}
                </div>
            );
        });

        return <div className="select-options">{options}</div>;
    }

    renderValue() {
        // if the component have been given a value prop, look up the first
        // option having that value and render it's label instead
        const option = this.props.options.find(o => o.value === this.props.value);
        let value: any = option ? option.label : String(this.props.value || "");

        value = option && option.value ? value : <span className="fg-grey-3">{this.props.emptyValue || ""}</span>;
 
        if (this.props.valuePrefix) {
            value = (
                <span><span className="select-value-prefix">{this.props.valuePrefix}</span>{value}</span>
            );
        }

        return value;
    }

    render()
    {
        const { value, onChange, valuePrefix, options, emptyValue, ...props } = this.props;
        return (
            <div className={"select" + (this.state.hasFocus ? " open" : "")}>
                <label>
                    <input
                        { ...props }
                        type="text"
                        value={ value && typeof value === "object" ? JSON.stringify(value) : value || "" }
                        ref={ node => this.input = node }
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onKeyDown={this.onKeyDown}
                        onChange={() => {}}
                        // readOnly
                    />
                    <div className="select-value">{ this.renderValue() }</div>
                    { this.renderOptions() }
                </label>
            </div>
        );
    }
}


