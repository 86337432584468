import { Action, Dispatch }   from "redux"
import { ThunkAction } from "redux-thunk";
import { RootState } from ".";
import { apiRequest } from "../lib"
import {
    SelectedAppState,
    MergeSelectedAppAction,
    MergeSelectedAppActionPayload,
    MERGE_SELECTED_APP
} from "./types";

const initialState: SelectedAppState = {
    loading: false,
    error: null,
    data: null,
    saved: false
};


export function merge(payload: MergeSelectedAppActionPayload): MergeSelectedAppAction {
    return { type: MERGE_SELECTED_APP, payload };
}

export function load(id: string | number): ThunkAction<void, RootState, unknown, Action<string>>
{
    return function (dispatch) {
        dispatch(merge({ loading: true, error: null }));
        return apiRequest(`/app/${id}`)
        .then(data => dispatch(merge({ data, error: null, loading: false })))
        .catch(error => dispatch(merge({ error, loading: false })));
    };
}

export function unload() {
    return { type: MERGE_SELECTED_APP, payload: { data: null, error: null, loading: false, saved: false } };
}

export function save(formData: any={}): (dispatch: Dispatch) => any
{
    return function (dispatch) {
        let uri = formData.id ? `/app/${formData.id}` : "/app";
        dispatch(merge({ loading: true }));
        return apiRequest(uri, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(formData),
            credentials: "include"
        })
        .then(data => dispatch(merge({ data, error: null, loading: false, saved: true })))
        .catch(error => dispatch(merge({ error, loading: false })));
    };
}

export default function selectedAppReducer(
    state = initialState,
    action: MergeSelectedAppAction
): SelectedAppState
{
    switch (action.type) {
        case MERGE_SELECTED_APP:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
