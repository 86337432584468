import DefaultImage from "./DefaultImage/index"
import { AppImageDescriptor } from "../store/types"

interface AppIconProps {
    name: string
    icon?: AppImageDescriptor | null
}

export default function AppIcon({ name, icon }: AppIconProps)
{
    if (icon && icon.service) {
        const url = icon.service === "data-url" ? icon.dataUrl : icon.secure_url || icon.url;
        if (url) {
            return <img alt="App Icon" title={ name } className="app-icon" src={ url } />
        }
    }

    return <DefaultImage name={ name } className="app-icon" />
}