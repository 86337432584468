import React, { ReactElement } from "react"


export default class Tag extends React.Component<{
    label: string | ReactElement
    onRemove: () => any
}>
{
    onKeyDown(event: React.KeyboardEvent)
    {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            event.stopPropagation();
            this.props.onRemove();
        }
    }

    render()
    {
        const { label, onRemove } = this.props;
        return (
            <div className="tag">
                { label }
                { typeof onRemove === "function" && <i
                    className="fas fa-times-circle close-btn"
                    title="Remove"
                    onClick={ () => onRemove() }
                    tabIndex={ 0 }
                    onKeyDown={ e => this.onKeyDown(e) }
                /> }
            </div>
        );
    }
}