import { Link } from "react-router-dom"

export default function LinkList({ links }: { links: { to: string, text: string }[] }) {
    return (
        <>
            { links.map((link, i) => (
                <span key={i}>{ i > 0 ? ", " : "" }<Link to={link.to}>{link.text}</Link></span>
            )) }
        </>
    )
}