import { createStore, combineReducers, applyMiddleware, compose, PreloadedState } from "redux"
import thunk/*, { ThunkMiddleware }*/ from "redux-thunk"
import apps        from "./apps"
import selectedApp from "./selectedApp"
import meta        from "./meta"
import * as lib    from "../lib"

const middleWares = [

    // This should be just `thunk` but that des not work properly when
    // loaded as ESM through Node and we need to use `thunk.default` instead
    // @ts-ignore
    thunk.default || thunk
];

// Create logger middleware that will log all redux action but only use that in development.
// if (lib.isBrowser() && lib.isDev()) {
//     let logger: ThunkMiddleware<RootState> = _store => {
//         return next => {
//             return action => {
//                 let result;
//                 if (!action.__no_log) {
//                     console.groupCollapsed(action.type);
//                     console.info("dispatching", action);
//                     result = next(action);
//                     console.log("next state", _store.getState());
//                     console.groupEnd();
//                 }
//                 else {
//                     result = next(action);
//                 }
//                 return result;
//             };
//         };
//     };

//     middleWares.push(logger);
// }

// @ts-ignore
const composeEnhancers = (lib.isBrowser() && lib.isDev() && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
    apps,
    selectedApp,
    meta
});

export type RootState = ReturnType<typeof rootReducer>

export default function storeCreator(preloadedState: PreloadedState<RootState> = {}) {
    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(applyMiddleware(...middleWares))
    );

    // The server should pass metadata in preloadedState. If we don't have it,
    // It means we did not use SSR (Webpack Dev Server), so go fetch it
    if (!preloadedState.meta?.categories) {
        store.dispatch({ type: "set-loading", payload: true });
        lib.apiRequest("/meta").then(meta => {
            store.dispatch({ type: "load", payload: meta });
            store.dispatch({ type: "set-loading", payload: false });
        });
    }

    return store;
};
