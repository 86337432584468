export interface AssociationRecord {
    id  : string | number
    name: string
    slug: string
}

// FHIR -----------------------------------------------------------------------

export interface FHIRRecord extends AssociationRecord {}

export type FHIRState = FHIRRecord[]


// Specialties ----------------------------------------------------------------

export interface SpecialtyRecord  extends AssociationRecord {}

export type SpecialtiesState = SpecialtyRecord[]


// Pricing --------------------------------------------------------------------

export interface PricingRecord  extends AssociationRecord {}

export type PricingState = PricingRecord[]


// EHR ------------------------------------------------------------------------

export interface EHRRecord  extends AssociationRecord {
    url_pattern: string
    url?: string
}

export type EHRState = EHRRecord[]


// DesignedFor ----------------------------------------------------------------

export interface DesignedForRecord  extends AssociationRecord {}

export type DesignedForState = DesignedForRecord[]


// Categories -----------------------------------------------------------------

export interface CategoryRecord  extends AssociationRecord {}

export type CategoriesState = CategoryRecord[]


// applicationType ------------------------------------------------------------

export interface ApplicationTypeRecord  extends AssociationRecord {
    description?: string
}

export type ApplicationTypeState = ApplicationTypeRecord[]


// OS -------------------------------------------------------------------------

export interface OSRecord extends AssociationRecord {}

export type OSState = OSRecord[]


// Auth -----------------------------------------------------------------------

export type UserRole = "admin" | "user"

export type AuthProvider = "twitter" | "google" | "github" | "email"

export interface User {
    role: UserRole
    email: string
    username: string
    id: number
    imageUrl?: string
}

export interface AuthState {
    user: User | null
    identities?: { id: number, provider: AuthProvider, external_id: string }[]
    authFlashMessage?: string
    needsForceToConnect?: string
}


// Apps -----------------------------------------------------------------------

export const MERGE_APPS = "apps/merge"

export type AppsFilterType = "category" | "designed_for" | "specialty" | "os" | "drafts" |
    "fhir" | "pricing" | "ehr" | "review" | "featured" | "search" | "advanced-search" | "type"

export type AppsSortType = "name-asc" | "name-desc" | "created-desc" | "updated-desc" | "rank"

export type AppListingStatus = "published" | "draft" | "submitted"

export type AppDemoType = "DSTU-2" | "STU-3" | "R4" | "R5" | "none"

export type AppType = "smart-on-fhir" | "bulk-data"

export interface RemoteImageDescriptor {
    external_id: string
    format: string
    height?: number
    secure_url: string
    service: "cloudinary" | "image"
    url?: string
    width?: number
    errors?: string[] // local only
}

export interface LocalImageDescriptor {
    format: string
    height?: number
    service: "data-url"
    width?: number
    dataUrl: string
    size : number
    errors?: string[] // local only
}

export type AppImageDescriptor = RemoteImageDescriptor | LocalImageDescriptor

export interface AppEHR {
    ehr_id: number
    url?: string
}

export interface AppOS {
    operating_system_id: number
    url?: string
}

export interface AppUser {
    username: string
    role: "author" | "editor"
}

export interface AppUserActions {
    can_approve: boolean
    can_delete: boolean
    can_edit: boolean
    can_submit: boolean

    /**
     * @deprecated
     */
    can_reject?: boolean
    can_set_editors: boolean
    can_set_featured: boolean

    /**
     * Admin if submitted
     * Admin, owner and editors if published
     */
    can_unpublish: boolean 
}

export interface App {
    id                   : number
    name                 : string
    is_featured          : boolean
    slug                 : string
    status               : AppListingStatus
    email               ?: string | null // undefined if we don't have access and null if unset
    url                  : string
    video_url            : string | null
    org_name             : string
    org_url              : string | null // ?
    org_description      : string | null // ?
    description          : string
    description_short    : string
    pricing_description  : string | null
    sales_contact       ?: string | null // undefined if we don't have access and null if unset
    has_sales_contact    : boolean
    updated_at           : string | null
    created_at           : string

    // Try button
    demo_launch_url      : string | null
    demo_patient_ids     : string | null
    demo_redirect_url    : string | null
    demo_requires_patient: boolean
    demo_type            : AppDemoType|null

    // Images
    icon        : AppImageDescriptor | null // TBD
    screenshot_1: AppImageDescriptor        // TBD
    screenshot_2: AppImageDescriptor | null // TBD
    screenshot_3: AppImageDescriptor | null // TBD

    user_actions: AppUserActions

    // relations ---------------------------------------------------------------
    designed_for_id    : number
    application_type_id: number
    category_ids       : number[]
    specialty_ids      : number[]
    fhir_ids           : number[]
    pricing_ids        : number[]
    ehrs               : AppEHR[]
    operating_systems  : AppOS[]
    users              : AppUser[] // list of editors
}

export interface AppsState {
    loading: boolean
    error: Error | null
    items: App[]
    page: number
    perPage: number
    totalCount: number
    pageCount: number
    count: number
    sort: AppsSortType
    uri: string
}

export interface MergeAppsActionPayload {
    loading?: boolean
    error?: Error | null
    items?: App[]
    page?: number
    perPage?: number
    totalCount?: number
    pageCount?: number
    count?: number
    sort?: AppsSortType
    uri?: string
}

export interface MergeAppsAction {
    type: typeof MERGE_APPS,
    payload: MergeAppsActionPayload
}

export interface AppsSearchOptions {
    q: string
    category: string
    designedFor: string
    os: string
    fhir: string
    pricing: string
    ehr: string
    type: string
    sort: string
    perPage: string
}

export interface SearchAppsAction {
    type: typeof MERGE_APPS,
    payload: any
}

// SelectedApp ----------------------------------------------------------------

export const MERGE_SELECTED_APP = "selectedApp/merge"

export interface SelectedAppState {
    loading: boolean
    saved: boolean
    error: Error | null
    data: App | null
}

export interface MergeSelectedAppActionPayload {
    loading?: boolean
    error?: Error | null
    data?: App
    saved?: boolean
}

export interface MergeSelectedAppAction {
    type: typeof MERGE_SELECTED_APP,
    payload: MergeSelectedAppActionPayload
}
