import React          from "react"
import { connect }    from "react-redux"
import { Helmet }     from "react-helmet"
import { Redirect }   from "react-router-dom"
import { RouteComponentProps, withRouter } from "react-router"
import { load, save, unload } from "../store/selectedApp"
import Form           from "../components/ApplicationForm/ApplicationForm"
import { App }        from "../store/types"
import { RootState }  from "../store"
import { MetaState }  from "../store/meta"
import Error403       from "../components/Error403"
import Error404       from "../components/Error404"
import { NotAuthenticatedError, NotAuthorizedError, NotFoundError } from "../errors"
import Login from "../Login"

interface EditApplicationParams {
    slug: string
}

interface EditApplicationProps extends RouteComponentProps<EditApplicationParams> {
    app     : App | null
    meta    : MetaState
    loading?: boolean
    error   : Error | null
    saved  ?: boolean
    load    : (id: string) => any
    save    : (data:any) => any
    unload  : () => any
}

interface EditApplicationState {
    loading: boolean
    app: App | null
}

class EditApplication extends React.Component<EditApplicationProps, EditApplicationState>
{
    // always reload if the user is about to edit an app
    componentDidMount()
    {
        this.props.load(this.props.match.params.slug);
    }

    componentWillUnmount()
    {
        this.props.unload();
    }

    render()
    {
        const { save, meta, loading, saved, error, app } = this.props;

        // Exit early on some errors (don't render the form in that case)
        if (error) {
            if (error instanceof NotAuthorizedError) {
                return <Error403 />
            }

            if (error instanceof NotFoundError) {
                return <Error404 />
            }

            if (error instanceof NotAuthenticatedError) {
                return <Login />
            }
        }

        if(!app || loading) {
            return null
        }

        // also check before loading the form if the user can edit that app
        if (app && !app.user_actions?.can_edit) {
            // Handle 403 after submit for review
            if (saved && app.status === "submitted") {
                return <Redirect to={{ pathname: "/user/apps", state: { saved: true }}} />
            }
            return <Error403/>
        }

        // Upon successful save go back to /user/apps
        if (saved && !error) {
            const currentUser = meta.auth.user
            return <Redirect to={
                (app?.users || []).find(u => u.username === currentUser?.username) ?
                    { pathname: "/user/apps", state: { saved: true }} :
                    { pathname: "/app/" + app.slug }
            } />
        }

        return (
            <>
                <Helmet>
                    <title>Edit Application</title>
                </Helmet>
                <Form
                    app={app}
                    save={save}
                    loading={loading}
                    error={error}
                    meta={meta}
                    key={app.id || "new-app"}
                />
            </>
        );
    }
}

export default connect(
    (state: RootState) => ({
        app    : state.selectedApp.data,
        loading: state.selectedApp.loading,
        error  : state.selectedApp.error,
        saved  : state.selectedApp.saved,
        meta   : state.meta
    }),
    {
        load,
        save,
        unload
    }
)(withRouter(EditApplication));
