import { useState } from "react"


export interface MenuButtonProps
{
    children: JSX.Element
    label: JSX.Element | string
    className?: string
}

export default function MenuButton({ children, label, className = "" }: MenuButtonProps)
{
    const [open, setOpen] = useState(false)

    const cls: Record<string, boolean> = {};

    className.trim().split(/\s+/).forEach(c => { cls[c] = true })

    cls["menu-button-wrap"] = true
    cls.open = open

    const classAttr = Object.keys(cls).filter(c => cls[c]).join(" ");

    return (
        <div className={ classAttr } tabIndex={0} onBlur={() => setOpen(false)}>
            <button
                className="btn btn-brand-2"
                onClick={() => setOpen(!open)}
            >
                { label }
            </button>
            <div className="menu-button-menu" onMouseDown={e => e.preventDefault()} onClick={() => setOpen(false)}>
                { children }
            </div>
        </div>
    )
}
