import React          from "react"
import { connect }    from "react-redux"
import { Helmet }     from "react-helmet"
import { withRouter, Redirect, RouteComponentProps } from "react-router-dom"
import { load }       from "../store/apps"
import AppList        from "../components/AppList/index"
import Sidebar        from "../components/Sidebar/index"
import Error404       from "../components/Error404"
import { RootState }  from "../store"
import config         from "../config"
import { App, AppsFilterType, AppsSortType } from "../store/types"
import { MetaState } from "../store/meta"

interface AppListContainerParams {
    filterType?: AppsFilterType
    filterValue?: string
    page?: string
}

interface AppListContainerProps extends RouteComponentProps<AppListContainerParams> {
    apps      : App[]
    loading   : boolean
    error     : Error | null
    page      : number
    pageCount : number
    totalCount: number
    sort      : AppsSortType
    uri       : string
    meta      : MetaState
    load      : (options: any) => any
}

class AppListContainer extends React.Component<AppListContainerProps>
{
    componentDidMount()
    {
        this.load();
    }

    /**
     * If the reason for the update is a location change, it means we have just
     * navigated and the new data needs to be loaded
     * @param {object} prevProps 
     */
    componentDidUpdate(prevProps: AppListContainerProps)
    {
        // This should not be called on the server but it will throw an error
        // while testing because JSDom cannot scroll

        /* istanbul ignore next */
        if (process.env.NODE_ENV !== "test" && typeof window !== "undefined") {
            window.scrollTo({ left: 0, top : 0, behavior: "smooth" });
        }

        this.load();
    }

    load()
    {
        // 1. `:filterType/:filterValue` is for filter having name and value
        // 2. `:filterType` is for boolean filters (no value required)
        // 3. Only one filter at a time is supported
        // ---------------------------------------------------------------------
        // /apps/:filterType/:filterValue/page/:page
        // /apps/:filterType/:filterValue
        // /apps/:filterType/page/:page
        // /apps/page/:page
        // /apps/:filterType
        // /apps
        // ---------------------------------------------------------------------
        // :filterType = category | designed_for | fhir | pricing | specialty | os
        // :filterType = review | featured | search
        // ---------------------------------------------------------------------
        // query = sort & q
        // uri = /apps[/filterType[/filterValue]][/page/:page]?query

        const { match, location } = this.props;
        const { filterType, filterValue, page } = match.params;
        const q = new URLSearchParams(location.search);
        this.props.load({
            filterType,
            filterValue,
            page,
            sort: q.get("sort"),
            q: q.get("q"),
            organization: q.get("organization")
        });
    }

    getPageTitle()
    {
        const { meta, match } = this.props;
        const { filterType, filterValue } = match.params;

        const filters = [

            // Name/value filters ----------------------------------------------
            {
                filterType : "category",
                source     : meta.categories,
                titlePrefix: "Category: "
            },
            {
                filterType : "designed_for",
                source     : meta.designedFor,
                titlePrefix: "Designed for: "
            },
            {
                filterType : "specialty",
                source     : meta.specialties,
                titlePrefix: "Specialty: "
            },
            {
                filterType : "os",
                source     : meta.operatingSystems,
                titlePrefix: "Supported OS: "
            },
            {
                filterType : "fhir",
                source     : meta.fhir,
                titlePrefix: "Supported FHIR Version: "
            },
            {
                filterType : "pricing",
                source     : meta.pricing,
                titlePrefix: "Pricing: "
            },
            {
                filterType : "ehr",
                source     : meta.ehr,
                titlePrefix: "EHR Support: "
            },
            {
                filterType : "type",
                source     : meta.applicationType,
                titlePrefix: "Application Type: "
            },

            // Boolean flag filters --------------------------------------------
            {
                filterType : "review",
                title      : "Apps for Review"
            },
            {
                filterType : "featured",
                title      : "Featured Apps"
            },
            {
                filterType : "search",
                title      : "Search Results"
            },
            {
                filterType : "drafts",
                title      : "Drafts"
            }
        ];

        if (config.advancedSearch.enabled) {
            filters.push({
                filterType : "advanced-search",
                title      : "Search Results"
            })
        }

        if (filterType) {
            const meta = filters.find(x => x.filterType === filterType);
            if (meta) {
                if (filterValue && meta.source) {
                    let item = meta.source.find(x => x.slug === filterValue);
                    if (item) {
                        return `${meta.titlePrefix}${item.name}`;
                    } else {
                        return `${meta.titlePrefix}${filterValue}`;
                    }
                } else {
                    return meta.title;
                }
            } else {
                // console.warn(`Unknown filter type "${filterType}"`);
                return null;
            }
        }

        return "All Apps";
    }

    onSort(sort: AppsSortType)
    {
        const { location, history } = this.props;
        const q = new URLSearchParams(location.search);
        q.set("sort", sort);
        history.push({
            pathname: location.pathname,
            search  : "?" + q.toString()
        });
    }

    render()
    {
        const {
            apps,
            page,
            pageCount,
            sort,
            loading,
            match,
            error,
            uri,
            meta,
            location,
            totalCount
        } = this.props;

        if (error) {
            return <pre>{error.message}</pre>
        }

        if (match.params.filterType === "review" || match.params.filterType === "drafts") {
            if (!meta.auth.user) {
                return <Redirect to={`/user/login?redirect=${location.pathname}`} />
            }
        }

        let title = this.getPageTitle();

        if (!title) {
            return <Error404 />
        }

        return (
            <>
                <Helmet>
                    <title>{ title }</title>
                </Helmet>
                <Sidebar
                    categories={ meta.categories }
                    designedFor={ meta.designedFor }
                    specialties={ meta.specialties }
                    pricing={ meta.pricing }
                    os={ meta.operatingSystems }
                    fhir={ meta.fhir }
                    ehr={ meta.ehr }
                    applicationTypes={ meta.applicationType }
                    sort={ sort }
                    filterType={ match.params.filterType }
                    filterValue={ match.params.filterValue }
                    user={ meta?.auth?.user || null }
                    key={ uri }
                />
                <AppList
                    loading={ loading }
                    apps={ apps }
                    meta={meta}
                    title={ title }
                    page={ page }
                    pageCount={ pageCount }
                    totalCount={ totalCount }
                    sort={ sort }
                    onSortChange={ sort => this.onSort(sort) }
                    showAdvancedSearch={ this.props.match.params.filterType === "advanced-search" }
                />
            </>
        );
    }
}

export default connect(
    (state: RootState) => ({
        apps      : state.apps.items,
        loading   : state.apps.loading,
        error     : state.apps.error,
        page      : state.apps.page,
        pageCount : state.apps.pageCount,
        sort      : state.apps.sort,
        uri       : state.apps.uri,
        totalCount: state.apps.totalCount,
        meta      : state.meta,
    }),
    { load } 
)(withRouter(AppListContainer));
