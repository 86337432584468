import React from "react"


const chars = "abcdefghijklmnopqrstuvwxyz0123456789-+()[]/\\*&^%$#@!~`?\":;., '";

/**
 * @param _abbr The app abbreviation
 * @param saturation The color saturation as percentage string
 * @param lightness The color lightness as percentage string
 * @returns Returns the generates color in HLS format
 */
function colorByAbbr(_abbr: string, saturation: string, lightness: string): string {
    const num = Math.floor(
        360 / _abbr.length *  (_abbr.toLowerCase().split("").reduce(
            (out, str) => out + chars.indexOf(str),
            0
        ) / _abbr.length)
    );
    return `hsl(${num}, ${saturation}, ${lightness})`;
}

/**
 * Gets the first characters of the first 3 words in the app name and uses them
 * to create an (upper-cased) abbreviation.
 * @param name The application name
 * @returns The application abbreviation
 */
function abbr(name: string = ""): string {
    return name.split(/\W+/)
        .slice(0, 3)
        .map(token => token.charAt(0).toUpperCase())
        .join("");
}

interface DefaultImageProps {
    name       : string
    saturation?: string
    lightness ?: string
    className ?: string
}

export default class DefaultImage extends React.Component<DefaultImageProps>
{
    static defaultProps = {
        saturation: "15%",
        lightness : "60%"
    };

    svg: SVGElement | null = null;

    componentDidMount()
    {
        (this.svg as SVGElement).style.opacity = "1";
    }

    render()
    {
        const { name, saturation, lightness, className } = this.props;
        const _abbr = abbr(name) || "?";

        return (
            <div className={(className || "") + " app-icon"} title={name}>
                <svg width="100" height="100" ref={ node => this.svg = node }
                    viewBox="0 0 100 100" preserveAspectRatio="xMaxYMax meet">
                    <circle cx="50" cy="50" r="50"
                        fill={colorByAbbr(_abbr, saturation as string, lightness as string)}/>
                    <text fill="#FFF" x="50" y="64"
                        fontSize="36" textAnchor="middle">{_abbr}</text>
                </svg>
            </div>
        );
    }
}
